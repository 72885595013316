
import Config from "../../../core/core/calcule/Config";
import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import LinePath from "../../../viewmodel/core/base/LinePath";
/**
 * ProjectName: MindMap
 * Created by tony on 5/2/21
 * Copyright(c) 2020 mindyushu.com
 */

class ExplainLabelView {
    constructor(context, MindElementData) {
        this.context = context;
        this.path;  //背景
        this.data = MindElementData;
        this.foreignObjectBox;
        this.label;
        this.initView();
    }
    setData(data) {
        this.data = data;
    }

    initView() {
        this.foreignObjectBox = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        if (this.path == null) {
            this.path = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.path)
        }
        this.label = document.createElement("div");
        this.foreignObjectBox.appendChild(this.label);
        this.context.appendChild(this.foreignObjectBox);
    }

    setPosition(width, height, left, top) {

        let radius = new UiUtil().dip2px(8)
        let linePath = new LinePath();
        linePath.setEffectRadius(radius);

        linePath.moveTo(left + radius, top);
        linePath.lineTo(left + width, top);
        linePath.lineTo(left + width, top + height);
        linePath.lineTo(left, top + height);
        linePath.lineTo(left, top);
        linePath.lineTo(left + radius, top);

        this.path.setAttribute("d", linePath.getLine())
        this.path.setAttribute("stroke", Colors.getUiColorByAlpha(Colors.black50, 0.25));
        this.path.setAttribute("stroke-width", 1);

        this.path.setAttribute("stroke-dasharray", "none");
        this.path.setAttribute("fill", Colors.getUiColorByAlpha(Colors.white, 0.8))

        this.label.style.whiteSpace = "nowrap"
        this.label.style.overflow = "hidden"
        this.label.style.textOverflow = "ellipsis"

        this.foreignObjectBox.setAttribute('width', width + 4);
        this.foreignObjectBox.setAttribute('height', height + 4);
        this.foreignObjectBox.setAttribute('x', left);
        this.foreignObjectBox.setAttribute('y', top);
        this.foreignObjectBox.style.userSelect = "none";

        this.label.innerHTML = this.data.explain;
        let labelWidht = width;
        this.label.style.width = labelWidht + 'px';
        this.label.style.height = height + 'px';
        this.label.style.fontSize = this.data.getNodeExplainFontSize() + 'px';
        // this.label.style.padding = Config.NodeExplainPadding + 'px';
        this.label.style.marginTop = Config.NodeExplainPadding + 'px';
        this.label.style.color = Colors.getUiColor(Colors.black48);
        //避免全部展示的时候出现省略号。
        // this.label.style.paddingLeft = Config.NodeExplainLeftAndRightPadding - 2 + 'px';
        // this.label.style.paddingRight = Config.NodeExplainLeftAndRightPadding - 2 + 'px';
        this.label.style.lineHeight = height - Config.NodeExplainPadding * 2 - 1 + "px";

        // this.label.style.borderRadius = new UiUtil().dip2px(8) + 'px';
        // this.label.style.borderStyle = "solid";
        // this.label.style.borderWidth = 1 + 'px';
        // this.label.style.borderColor = Colors.getUiColorByAlpha(Colors.black50, 0.25);
        // this.label.style.backgroundColor = Colors.getUiColorByAlpha(Colors.white, 0.8);
        this.label.style.textAlign = "center";

    }

    refreshView() {

    }

}

export default ExplainLabelView
