import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import LinePath from "../../../viewmodel/core/base/LinePath";

/**
 * ProjectName: MindMap
 * Created by tony on 2/13/21
 * Copyright(c) 2020 mindyushu.com
 */


class CheckBoxView {
    constructor(fatherDom, size, baseInput, data) {
        this.contentG;
        this.linePath = new LinePath();
        this.selectedPath = new LinePath();
        this.lineHeight = new UiUtil().dip2px(2);
        this.lineView;
        this.selectedView;
        this.size = size;
        this.fatherDom = fatherDom;
        this.mousdownFunction;
        this.baseInput = baseInput;
        this.data = data;
        this.lastMousedownTime = -1;
        this.initView();

    }


    initView() {
        this.contentG = document.createElementNS('http://www.w3.org/2000/svg', 'g')
        this.contentG.style.width = this.size.width + 'px';
        this.contentG.style.height = this.size.height + 'px';
        this.contentG.setAttribute('id', 'accddff');
        this.contentG.style.transform = 'translate(' + 20 + 'px,' + 10 + 'px)';
        this.contentG.style.cursor = 'pointer';
        this.fatherDom.appendChild(this.contentG);

        this.lineView = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        this.contentG.appendChild(this.lineView);

        this.linePath.setEffectRadius(2)
        this.linePath.moveTo(0, this.size.height / 2);
        this.linePath.lineTo(0, 0);
        this.linePath.lineTo(this.size.width, 0);
        this.linePath.lineTo(this.size.width, this.size.height);
        this.linePath.lineTo(0, this.size.height);
        this.linePath.lineTo(0, this.size.height / 2);

        this.renderCanvas(this.linePath, this.lineView);

        this.selectedView = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        this.contentG.appendChild(this.selectedView);

        let leftAndRightRatio = 0.237
        let topAndBottomRatio = 0.286
        let startTopRatio = 0.535
        let bottomPointLeftRatio = 0.372

        let leftPointX = this.size.width * leftAndRightRatio
        let leftPointY = this.size.height * startTopRatio

        let bottomPointX = this.size.width * bottomPointLeftRatio
        let bottomPointY = this.size.height * (1 - topAndBottomRatio)

        let rightPointX = this.size.width * (1 - leftAndRightRatio)
        let rightPointY = this.size.height * topAndBottomRatio

        this.selectedPath.moveTo(leftPointX, leftPointY);
        this.selectedPath.lineTo(bottomPointX, bottomPointY);
        this.selectedPath.lineTo(rightPointX, rightPointY);

        this.renderSelectedCanvas(this.selectedPath, this.selectedView);

        this.bundling()

    }

    setData(data, size) {
        this.data = data
        this.size = size

        this.linePath.clear()
        this.selectedPath.clear()

        this.contentG.style.width = this.size.width + 'px';
        this.contentG.style.height = this.size.height + 'px';

        this.linePath.moveTo(0, this.size.height / 2);
        this.linePath.lineTo(0, 0);
        this.linePath.lineTo(this.size.width, 0);
        this.linePath.lineTo(this.size.width, this.size.height);
        this.linePath.lineTo(0, this.size.height);
        this.linePath.lineTo(0, this.size.height / 2);

        let leftAndRightRatio = 0.237
        let topAndBottomRatio = 0.286
        let startTopRatio = 0.535
        let bottomPointLeftRatio = 0.372

        let leftPointX = this.size.width * leftAndRightRatio
        let leftPointY = this.size.height * startTopRatio

        let bottomPointX = this.size.width * bottomPointLeftRatio
        let bottomPointY = this.size.height * (1 - topAndBottomRatio)

        let rightPointX = this.size.width * (1 - leftAndRightRatio)
        let rightPointY = this.size.height * topAndBottomRatio

        this.selectedPath.moveTo(leftPointX, leftPointY);
        this.selectedPath.lineTo(bottomPointX, bottomPointY);
        this.selectedPath.lineTo(rightPointX, rightPointY);

        this.renderCanvas(this.linePath, this.lineView);
        this.renderSelectedCanvas(this.selectedPath, this.selectedView);
    }

    //自己添加的一个函数
    renderCanvas(path, element) {
        element.setAttribute("d", path.getLine())
        if (this.data != null && this.data.checkBoxContent != null && this.data.checkBoxContent.selected) {
            element.setAttribute("stroke", Colors.getUiColor(Colors.mainThemeColor))
            element.setAttribute("fill", Colors.getUiColor(Colors.mainThemeColor))
        } else {
            element.setAttribute("stroke", Colors.getUiColor(Colors.black40, 1.0))
            element.setAttribute("fill", Colors.getUiColor(Colors.white, 1.0))
        }
        
        element.setAttribute("stroke-width", 1)
    }

    renderSelectedCanvas(path, element) {
        if (this.data != null && this.data.checkBoxContent != null && this.data.checkBoxContent.selected) {
            element.setAttribute("d", path.getLine())
            element.setAttribute("stroke-linecap", "round")
            element.setAttribute("stroke", Colors.getUiColor(Colors.white))
            element.setAttribute("fill", "none")
            element.setAttribute("stroke-width", 2)
            if (this.lastMousedownTime > 0 && Util.getCurrentTime() - this.lastMousedownTime < 2000) {
                element.style.strokeDashoffset = element.getTotalLength()
                element.style.strokeDasharray = element.getTotalLength()
                element.style.animation = 'path-line-animation-dash 0.2s linear forwards'
            }
            element.style.display = 'block';
        } else {
            element.style.display = 'none';
        }
    }

    bundling() {
        this.mousdownFunction = (ev) => {
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            if (this.data != null && this.data.checkBoxContent != null && !this.data.checkBoxContent.selected) {
                this.lastMousedownTime = Util.getCurrentTime();
            } else {
                this.lastMousedownTime = -1;
            }
            this.baseInput.mindTextSizeChangedDelegate.onMousedownCheckbox(this.data.id);
        }
        this.contentG.addEventListener('mousedown', this.mousdownFunction)
    }

    setPoint(x, y) {
        this.showDom();
        this.contentG.style.transform = 'translate(' + x + 'px,' + y + 'px)';
    }

    showDom() {
        this.contentG.style.display = 'block';
    }

    hideen() {
        this.contentG.style.display = 'none';
    }
}

export default CheckBoxView   
