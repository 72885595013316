import UiUtil from "../../../utils/UiUtil"
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType"
import MindElementShapeType from "../../../viewmodel/datatype/MindElementShapeType"
import MindType from "../../../viewmodel/datatype/MindType"
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit"

import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit"
import HashMap from "../../../viewmodel/core/base/HashMap"
import Point from "../../../viewmodel/core/base/Point"
import Colors from "../../../utils/Colors"
import TextContent from "../../../viewmodel/mindelementdata/mindcontent/TextContent"
import Strings from "../../../utils/Strings"
import MindElementCalculation from "./elementCalculation/MindElementCalculation"
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint"
import NodeConnectLineHit from "./NodeConnectLineHit"
import strings from "../../../common/lang/strings"
import LinePointShape from "../../../viewmodel/datatype/LinePointShape"
import MindElementType from "../../../viewmodel/datatype/MindElementType"
import Util from "../../../utils/Util"
import PointAndTime from "../basemode/PointAndTime"
import CGRect from "../../../viewmodel/core/base/basedata/Rect"
import FlowChartDirection from "../../../viewmodel/datatype/FlowChartDirection"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/12
 * Copyright(c) 2020 mindyushu.com
 */

class NodeConnectLineCalcul {

    constructor() {
        this.MaxDeviationValue = new UiUtil().dip2px(10);
        this.MaxDistance = new UiUtil().dip2px(600);
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.freeNodes = new Array();
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.mindBGColor = 0x333333;
        this.mindType = MindType.LINE_MAP;
        this.lastClickPoint = new PointAndTime();
    }

    setData(mindType, mainMindNodeUnit, freeNodes, nodeConnectLineDataDict, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit, mindBGColor, lastClickPoint) {
        this.mindType = mindType;
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
        this.mindBGColor = mindBGColor;
        this.lastClickPoint = lastClickPoint;
    }

    refreshNodeConnectLineLayout() {
        let keys = this.nodeConnectLineDataDict.keys();
        let length = keys.length
        for (let index = 0; index < length; index++) {
            let line = this.nodeConnectLineDataDict.get(keys[index]);
            this.setConnectLine(line)
        }
    }

    moveConnectLinePoint(line, point, isStart) {
        if (line == null || line.isEmpty() || line.lineContent == null) {
            return
        }
        let target = this.getNodeById(line.parentNodeId).value;
        let to = this.getNodeById(line.lineContent.targetId).value;
        if (target.isEmpty() || to.isEmpty()) {
            return
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return;
        }
        let isStraightLine = this.isStraightLine(line)
        let isFreeLine = this.isFreeLine(line)   
        let isChangeTarget = false;
        let width = line.width
        let height = line.height
        let startPointX = lineContent.startPointX
        let startPointY = lineContent.startPointY
        let endPointX = lineContent.endPointX
        let endPointY = lineContent.endPointY
        let startControlPointX = lineContent.startControlPointX;
        let startControlPointY = lineContent.startControlPointY;
        let endControlPointX = lineContent.endControlPointX;
        let endControlPointY = lineContent.endControlPointY;

        if (isStart) {
            let moveX = point.x - (startPointX + line.x);
            let moveY = point.y - (startPointY + line.y);
            let margin = lineContent.getLineStartMargin();
            if (target.type == MindElementType.BAOWEI_VIEW) {
                let findData = this.findHitData(point, margin)
                if (!findData.isEmpty()) {
                    if (findData.id != target.id) {
                        let shapeHit = findData.hitPoint(point, margin);
                        if (shapeHit.x > -1 && shapeHit.y > -1) {
                            moveX = shapeHit.x - (startPointX + line.x);
                            moveY = shapeHit.y - (startPointY + line.y);
                            target = findData;
                            line.parentNodeId = target.id
                            isChangeTarget = true;
                        }
                    } else {
                        let shapeHit = target.hitPoint(point, margin);
                        if (shapeHit.x > -1 && shapeHit.y > -1) {
                            moveX = shapeHit.x - (startPointX + line.x);
                            moveY = shapeHit.y - (startPointY + line.y);
                        }
                    }
                }
            } else {
                let shapeHit = target.hitPoint(point, margin);            
                if (shapeHit.x > -1 && shapeHit.y > -1) {
                    moveX = shapeHit.x - (startPointX + line.x);
                    moveY = shapeHit.y - (startPointY + line.y);
                } else {
                    let findData = this.findHitData(point, margin)
                    if (!findData.isEmpty()) {
                        shapeHit = findData.hitPoint(point, margin);
                        if (shapeHit.x > -1 && shapeHit.y > -1) {
                            moveX = shapeHit.x - (startPointX + line.x);
                            moveY = shapeHit.y - (startPointY + line.y);
                            target = findData;
                            line.parentNodeId = target.id
                            isChangeTarget = true;
                        }
                    }
                } 
            }
                    
            lineContent.startPointX += moveX
            lineContent.startPointY += moveY

            lineContent.startControlPointX += moveX
            lineContent.startControlPointY += moveY            
        } else {
            let margin = lineContent.getLineEndMargin();            
            let moveX = point.x - (endPointX + line.x);
            let moveY = point.y - (endPointY + line.y);
            if (to.type == MindElementType.BAOWEI_VIEW) {
                let findData = this.findHitData(point, margin)
                if (!findData.isEmpty()) {
                    if (findData.id != to.id) {
                        let shapeHit = findData.hitPoint(point, margin);
                        if (shapeHit.x > -1 && shapeHit.y > -1) {
                            moveX = shapeHit.x - (endPointX + line.x);
                            moveY = shapeHit.y - (endPointY + line.y);
                            to = findData;
                            line.lineContent.targetId = to.id
                            isChangeTarget = true;
                        }
                    } else {
                        let shapeHit = to.hitPoint(point, margin);
                        if (shapeHit.x > -1 && shapeHit.y > -1) {
                            moveX = shapeHit.x - (endPointX + line.x);
                            moveY = shapeHit.y - (endPointY + line.y);
                        }
                    }
                }
            } else {
                let shapeHit = to.hitPoint(point, margin);
                if (shapeHit.x > -1 && shapeHit.y > -1) {
                    moveX = shapeHit.x - (endPointX + line.x);
                    moveY = shapeHit.y - (endPointY + line.y);
                } else {
                    let findData = this.findHitData(point, margin)
                    if (!findData.isEmpty()) {
                        shapeHit = findData.hitPoint(point, margin);
                        if (shapeHit.x > -1 && shapeHit.y > -1) {
                            moveX = shapeHit.x - (endPointX + line.x);
                            moveY = shapeHit.y - (endPointY + line.y);
                            to = findData;
                            line.lineContent.targetId = to.id
                            isChangeTarget = true;
                        }
                    }
                } 
            }
            
            lineContent.endPointX += moveX
            lineContent.endPointY += moveY

            lineContent.endControlPointX += moveX
            lineContent.endControlPointY += moveY 
        }

        if (lineContent.startPointX < lineContent.endPointX) {
            line.x = line.x + lineContent.startPointX                
            lineContent.endPointX -= lineContent.startPointX
            lineContent.startPointX = 0
        } else {
            line.x = line.x + lineContent.endPointX                
            lineContent.startPointX -= lineContent.endPointX
            lineContent.endPointX = 0
        }
        if (lineContent.startPointY < lineContent.endPointY) {
            line.y = line.y + lineContent.startPointY              
            lineContent.endPointY -= lineContent.startPointY
            lineContent.startPointY = 0
        } else {
            line.y = line.y + lineContent.endPointY             
            lineContent.startPointY -= lineContent.endPointY
            lineContent.endPointY = 0
        }
        line.width = Math.abs(lineContent.startPointX - lineContent.endPointX);
        line.height = Math.abs(lineContent.startPointY - lineContent.endPointY);
        
        if (isStraightLine && isFreeLine && lineContent.rightAnglePoints.length > 0) {
            let startPoint = new Point(startPointX, startPointY)
            let endPoint = new Point(endPointX, endPointY)
            let newStartPoint = lineContent.startPoint()
            let newEndPoint = lineContent.endPoint()
            if (Math.abs(newStartPoint.x - newEndPoint.x) <= 4) {
                if (isStart) {
                    lineContent.startPointX = lineContent.endPointX
                } else {
                    lineContent.endPointX = lineContent.startPointX
                }
            }
            if (Math.abs(newStartPoint.y - newEndPoint.y) <= 4) {
                if (isStart) {
                    lineContent.startPointY = lineContent.endPointY
                } else {
                    lineContent.endPointY = lineContent.startPointY
                }
            }
            
            for (let index = 0; index < lineContent.rightAnglePoints.length; index++) {
                let cell = lineContent.rightAnglePoints[index]
                let startSpace = Util.getPointSpacing(cell, startPoint)
                let endSpace = Util.getPointSpacing(cell, endPoint)                
                if (isStart) {
                    let newStartSpace = Util.getPointSpacing(newStartPoint, newEndPoint) * (endSpace/(startSpace + endSpace))
                    let degrees = Util.getCircleDegreesInPoint(newEndPoint, newStartPoint)
                    let newCell = Util.getCirclePoint(newEndPoint, degrees, newStartSpace)
                    cell.x = newCell.x
                    cell.y = newCell.y
                } else {
                    let newStartSpace = Util.getPointSpacing(newStartPoint, newEndPoint) * (startSpace/(startSpace + endSpace))
                    let degrees = Util.getCircleDegreesInPoint(newStartPoint, newEndPoint)
                    let newCell = Util.getCirclePoint(newStartPoint, degrees, newStartSpace)
                    cell.x = newCell.x
                    cell.y = newCell.y
                }
                
            }
        }
        this.checkLinePoint(line);
        this.setConnectLineText(line, lineContent);
    }

    checkConnectLineAnchorPoint(line) {
        if (line == null || line.isEmpty() || line.lineContent == null || this.mindType == MindType.FLOW_CHART) {
            return
        }
        let target = this.getNodeById(line.parentNodeId).value;
        let to = this.getNodeById(line.lineContent.targetId).value;
        if (target.isEmpty() || to.isEmpty()) {
            return
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return;
        }
        let startGlobalPoint = new Point(lineContent.startPointX + line.x, lineContent.startPointY + line.y);
        let endGlobalPoint = new Point(lineContent.endPointX + line.x, lineContent.endPointY + line.y);
        let checkStartPoint = target.hitPoint(startGlobalPoint, lineContent.getLineStartMargin())
        if (checkStartPoint.x <= -1 || checkStartPoint.y <= -1) {
            lineContent.startPointX = startPoint.x
            lineContent.startPointY = startPoint.y
        } else if(checkStartPoint.x != startGlobalPoint.x || checkStartPoint.y != startGlobalPoint.x) {
            lineContent.startPointX = checkStartPoint.x - line.x;
            lineContent.startPointY = checkStartPoint.y - line.y;
        }
        let checkEndPoint = to.hitPoint(endGlobalPoint, lineContent.getLineEndMargin())
        if (checkEndPoint.x <= -1 && checkEndPoint.y <= -1) {
            lineContent.endPointX = endPoint.x
            lineContent.endPointY = endPoint.y
        } else if (checkEndPoint.x != endGlobalPoint.x || checkEndPoint.y != endGlobalPoint.x) {
            lineContent.endPointX = checkEndPoint.x - line.x;
            lineContent.endPointY = checkEndPoint.y - line.y;
        }
        this.checkLinePoint(line);
        // if (isChangeTarget) {
        //     this.setRightAnglePoint(line, target, to);
        // }        
        this.setConnectLineText(line, lineContent);
    }

    moveConnectLineRightAnglePoint(line, point, index) {
        if (line == null || line.isEmpty() || line.lineContent == null ||
            line.lineContent.rightAnglePoints.length < index + 1) {
            return
        }
        let lineContent = line.lineContent;

        let target = this.getNodeById(line.parentNodeId).value;
        let to = this.getNodeById(line.lineContent.targetId).value;

        let originalX = lineContent.rightAnglePoints[index].x
        let originalY = lineContent.rightAnglePoints[index].y

        let startPoint = lineContent.startPoint();
        let endPoint = lineContent.endPoint();
        let firstPoint = new Point(lineContent.rightAnglePoints[0].x, lineContent.rightAnglePoints[0].y);
        let listPoint = new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, 
            lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y);
        if (lineContent.rightAnglePoints.length == 2) {
            if (index == 1 && Math.abs(lineContent.rightAnglePoints[0].x + line.x - point.x) < 6 && 
                Math.abs(startPoint.x + line.x - point.x) < 6 && 
                Math.abs(endPoint.x + line.x - point.x) < 6) { //欲竖直
                lineContent.rightAnglePoints[index].x = lineContent.rightAnglePoints[0].x
                lineContent.rightAnglePoints[index].y = point.y - line.y
            } else if (index == 0 && Math.abs(lineContent.rightAnglePoints[1].x + line.x - point.x) < 6 && 
                Math.abs(startPoint.x + line.x - point.x) < 6 && 
                Math.abs(endPoint.x + line.x - point.x) < 6) { //欲竖直
                lineContent.rightAnglePoints[index].x = lineContent.rightAnglePoints[1].x
                lineContent.rightAnglePoints[index].y = point.y - line.y
            } else if (index == 1 && Math.abs(lineContent.rightAnglePoints[0].y + line.y - point.y) < 6 && 
                Math.abs(startPoint.y + line.y - point.y) < 6 && 
                Math.abs(endPoint.y + line.y - point.y) < 6) { //欲横向
                lineContent.rightAnglePoints[index].x = point.x - line.x
                lineContent.rightAnglePoints[index].y = lineContent.rightAnglePoints[0].y
            } else if (index == 0 && Math.abs(lineContent.rightAnglePoints[1].y + line.y - point.y) < 6 && 
                Math.abs(startPoint.y + line.y - point.y) < 6 && 
                Math.abs(endPoint.y + line.y - point.y) < 6) { //欲横向
                lineContent.rightAnglePoints[index].x = point.x - line.x
                lineContent.rightAnglePoints[index].y = lineContent.rightAnglePoints[1].y
            } else {
                lineContent.rightAnglePoints[index].x = point.x - line.x
                lineContent.rightAnglePoints[index].y = point.y - line.y
            }
        } else {
            lineContent.rightAnglePoints[index].x = point.x - line.x
            lineContent.rightAnglePoints[index].y = point.y - line.y

            let changePoint = lineContent.rightAnglePoints[index]
            let originalPoint = new Point(originalX, originalY)
            if (index == 0 && lineContent.rightAnglePoints.length > 1) {
                const cell = lineContent.rightAnglePoints[1];
                let originalSpace = Util.getPointSpacing(originalPoint, cell)
                let space = Util.getPointSpacing(changePoint, cell)
                if (space > 0 && space <= 5 && originalSpace > space) {
                    if (Math.abs(changePoint.x - cell.x) <= 5 && Math.abs(changePoint.y - cell.y) <= 5) {
                        lineContent.rightAnglePoints[index].x = cell.x
                        lineContent.rightAnglePoints[index].y = cell.y
                    } else if (Math.abs(changePoint.x - cell.x) <= 5) {
                        lineContent.rightAnglePoints[index].x = cell.x
                    } else {
                        lineContent.rightAnglePoints[index].y = cell.y
                    }
                }
            } else if (index == lineContent.rightAnglePoints.length - 1 && lineContent.rightAnglePoints.length > 1) {
                const cell = lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 2];
                let originalSpace = Util.getPointSpacing(originalPoint, cell)
                let space = Util.getPointSpacing(changePoint, cell)
                if (space > 0 && space <= 5 && originalSpace > space) {
                    if (Math.abs(changePoint.x - cell.x) <= 5 && Math.abs(changePoint.y - cell.y) <= 5) {
                        lineContent.rightAnglePoints[index].x = cell.x
                        lineContent.rightAnglePoints[index].y = cell.y
                    } else if (Math.abs(changePoint.x - cell.x) <= 5) {
                        lineContent.rightAnglePoints[index].x = cell.x
                    } else {
                        lineContent.rightAnglePoints[index].y = cell.y
                    }
                }
            } else if (index > 0 && index < lineContent.rightAnglePoints.length - 1 && 
                lineContent.rightAnglePoints.length > 2) {
                const cell1 = lineContent.rightAnglePoints[index - 1];
                const cell2 = lineContent.rightAnglePoints[index + 1];
                let space1 = Util.getPointSpacing(changePoint, cell1);
                let space2 = Util.getPointSpacing(changePoint, cell2);

                let cell = space1 < space2 ? cell1 : cell2;                
                let originalSpace = Util.getPointSpacing(originalPoint, cell)
                let space = space1 < space2 ? space1 : space2;    
                if (space > 0 && space <= 5 && originalSpace > space) {
                    if (Math.abs(changePoint.x - cell.x) <= 5 && Math.abs(changePoint.y - cell.y) <= 5) {
                        lineContent.rightAnglePoints[index].x = cell.x
                        lineContent.rightAnglePoints[index].y = cell.y
                    } else if (Math.abs(changePoint.x - cell.x) <= 5) {
                        lineContent.rightAnglePoints[index].x = cell.x
                    } else {
                        lineContent.rightAnglePoints[index].y = cell.y
                    }
                }                
            }
        }        
        if (firstPoint.x == startPoint.x && firstPoint.y != startPoint.y) {            
            lineContent.startPointX = lineContent.rightAnglePoints[0].x
        } else if (firstPoint.x != startPoint.x && firstPoint.y == startPoint.y) {
            lineContent.startPointY = lineContent.rightAnglePoints[0].y
        }
        if (listPoint.x == endPoint.x && listPoint.y != endPoint.y) {
            lineContent.endPointX = lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x
        } else if (listPoint.x != endPoint.x && listPoint.y == endPoint.y) {
            lineContent.endPointY = lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y
        }
        let startGlobalPoint = new Point(lineContent.startPointX + line.x, lineContent.startPointY + line.y);
        let endGlobalPoint = new Point(lineContent.endPointX + line.x, lineContent.endPointY + line.y);
        let checkStartPoint = target.hitPoint(startGlobalPoint, lineContent.getLineStartMargin())
        if (checkStartPoint.x <= -1 && checkStartPoint.y <= -1) {
            lineContent.startPointX = startPoint.x
            lineContent.startPointY = startPoint.y
        } else if(checkStartPoint.x != startGlobalPoint.x || checkStartPoint.y != startGlobalPoint.x) {
            lineContent.startPointX = checkStartPoint.x - line.x;
            lineContent.startPointY = checkStartPoint.y - line.y;
        }
        let checkEndPoint = to.hitPoint(endGlobalPoint, lineContent.getLineEndMargin())
        if (checkEndPoint.x <= -1 && checkEndPoint.y <= -1) {
            lineContent.endPointX = endPoint.x
            lineContent.endPointY = endPoint.y
        } else if (checkEndPoint.x != endGlobalPoint.x || checkEndPoint.y != endGlobalPoint.x) {
            lineContent.endPointX = checkEndPoint.x - line.x;
            lineContent.endPointY = checkEndPoint.y - line.y;
        }
        let changePoint = lineContent.rightAnglePoints[index]
        let originalPoint = new Point(originalX, originalY)
        for (let j = 0; j < lineContent.rightAnglePoints.length; j++) {
            const cell = lineContent.rightAnglePoints[j];
            if (j == index) {
                continue
            }
            let originalSpace = Util.getPointSpacing(originalPoint, cell)
            let space = Util.getPointSpacing(changePoint, cell)
            if (originalX == cell.x && originalY != cell.y) {
                cell.x = lineContent.rightAnglePoints[index].x
            } else if (originalX != cell.x && originalY == cell.y) {
                cell.y = lineContent.rightAnglePoints[index].y
            } else if (space <= 5 && originalSpace > space) {
                if (Math.abs(changePoint.x - cell.x) <= 5 && Math.abs(changePoint.y - cell.y) <= 5) {
                    cell.x = lineContent.rightAnglePoints[index].x
                    cell.y = lineContent.rightAnglePoints[index].y
                } else if (Math.abs(changePoint.x - cell.x) <= 5) {
                    cell.x = lineContent.rightAnglePoints[index].x
                } else {
                    cell.y = lineContent.rightAnglePoints[index].y
                }
            }
        }

        this.checkLinePoint(line);
        this.setConnectLineText(line, lineContent);
    }    

    checkLinePoint(line) {
        if (line == null || line.isEmpty() || line.lineContent == null) {
            return
        }
        this.rectifyStartAndEndPoint(line)
        // return
        let lineContent = line.lineContent;
        let isStraightLine = this.isStraightLine(line)
        let isFreeLine = this.isFreeLine(line)
        let moveX = 0
        let moveY = 0
        if (lineContent.startPointX < lineContent.endPointX) {
            line.x = line.x + lineContent.startPointX                
            lineContent.endPointX -= lineContent.startPointX
            moveX = lineContent.startPointX
            lineContent.startPointX = 0
            
        } else {
            line.x = line.x + lineContent.endPointX                
            lineContent.startPointX -= lineContent.endPointX
            moveX = lineContent.endPointX
            lineContent.endPointX = 0
        }
        if (lineContent.startPointY < lineContent.endPointY) {
            line.y = line.y + lineContent.startPointY              
            lineContent.endPointY -= lineContent.startPointY
            moveY = lineContent.startPointY
            lineContent.startPointY = 0            
        } else {
            line.y = line.y + lineContent.endPointY             
            lineContent.startPointY -= lineContent.endPointY
            moveY = lineContent.endPointY
            lineContent.endPointY = 0
        }
        for (let j = 0; j < lineContent.rightAnglePoints.length; j++) {
            lineContent.rightAnglePoints[j].x -= moveX
            lineContent.rightAnglePoints[j].y -= moveY
        }
        if (lineContent.rightAnglePoints.length > 0 && (!isStraightLine || !isFreeLine)) {
            if (lineContent.rightAnglePoints[0].x != lineContent.startPointX && 
                lineContent.rightAnglePoints[0].y != lineContent.startPointY) {
               if (Math.abs(lineContent.rightAnglePoints[0].x - lineContent.startPointX) <
                    Math.abs(lineContent.rightAnglePoints[0].y - lineContent.startPointY)) {
                    lineContent.rightAnglePoints[0].x = lineContent.startPointX
               } else {
                    lineContent.rightAnglePoints[0].y = lineContent.startPointY
               }
            }            
            for (let index = 1; index < lineContent.rightAnglePoints.length; index++) {
                const cell = lineContent.rightAnglePoints[index];
                let per = lineContent.rightAnglePoints[index - 1]
                if (cell.x != per.x && 
                    cell.y != per.y) {
                   if (Math.abs(cell.x - per.x) <
                        Math.abs(cell.y - per.y)) {
                        cell.x = per.x
                   } else {
                        cell.y = per.y
                   }
                }
            }
            let lastIndex = lineContent.rightAnglePoints.length - 1;
            if (lineContent.rightAnglePoints[lastIndex].x != lineContent.endPointX && 
                lineContent.rightAnglePoints[lastIndex].y != lineContent.endPointY) {
                let to = this.getNodeById(line.lineContent.targetId).value;
                let endGlobalPoint = new Point(lineContent.endPointX + line.x, lineContent.endPointY + line.y);
                let lastGlobalPoint = new Point(lineContent.rightAnglePoints[lastIndex].x + line.x, lineContent.rightAnglePoints[lastIndex].y + line.y)
                if (to.isEmpty()) {
                    if (Math.abs(lineContent.rightAnglePoints[lastIndex].x - lineContent.endPointX) <
                        Math.abs(lineContent.rightAnglePoints[lastIndex].y - lineContent.endPointY)) {
                        lineContent.rightAnglePoints[lastIndex].x = lineContent.endPointX
                    } else {
                        lineContent.rightAnglePoints[lastIndex].y = lineContent.endPointY
                    }
                } else if (to.isTop(endGlobalPoint) &&
                    to.isTop(lastGlobalPoint) &&
                    !to.isLeft(endGlobalPoint) &&
                    !to.isRight(endGlobalPoint)) {
                    let newPoint = new Point(lineContent.endPointX, lineContent.rightAnglePoints[lastIndex].y)
                    lineContent.rightAnglePoints.push(newPoint)
                } else if (to.isBottom(endGlobalPoint) &&
                    to.isBottom(lastGlobalPoint) &&
                    !to.isLeft(endGlobalPoint) &&
                    !to.isRight(endGlobalPoint)) {
                    let newPoint = new Point(lineContent.endPointX, lineContent.rightAnglePoints[lastIndex].y)
                    lineContent.rightAnglePoints.push(newPoint)
                } else if (to.isLeft(endGlobalPoint) &&
                    to.isLeft(lastGlobalPoint) &&
                    !to.isTop(endGlobalPoint) &&
                    !to.isBottom(endGlobalPoint)) {
                    let newPoint = new Point(lineContent.rightAnglePoints[lastIndex].x, lineContent.endPointY)
                    lineContent.rightAnglePoints.push(newPoint)
                } else if (to.isRight(endGlobalPoint) &&
                    to.isRight(lastGlobalPoint) &&
                    !to.isTop(endGlobalPoint) &&
                    !to.isBottom(endGlobalPoint)) {
                    let newPoint = new Point(lineContent.rightAnglePoints[lastIndex].x, lineContent.endPointY)
                    lineContent.rightAnglePoints.push(newPoint)
                } else if (Math.abs(lineContent.rightAnglePoints[lastIndex].x - lineContent.endPointX) <
                    Math.abs(lineContent.rightAnglePoints[lastIndex].y - lineContent.endPointY)) {
                    lineContent.rightAnglePoints[lastIndex].x = lineContent.endPointX
                } else {
                    lineContent.rightAnglePoints[lastIndex].y = lineContent.endPointY
                }
            }
        }
        if (lineContent.rightAnglePoints.length > 0 && (!isStraightLine || !isFreeLine)) {
            let target = this.getNodeById(line.parentNodeId).value;
            let to = this.getNodeById(line.lineContent.targetId).value;
            if (!target.isEmpty() && target.type != MindElementType.NODE_CONNECT_LINE) {
                let startGlobalPoint = new Point(lineContent.startPointX + line.x, lineContent.startPointY + line.y);
                if (target.isLeft(startGlobalPoint) && 
                    !target.isTop(startGlobalPoint) &&
                    !target.isBottom(startGlobalPoint) && 
                    lineContent.rightAnglePoints[0].x >= lineContent.startPointX) {
                    let newPoint = new Point(lineContent.startPointX - 20, lineContent.startPointY)
                    lineContent.rightAnglePoints.splice(0, 0, newPoint)
                    lineContent.rightAnglePoints[1].x = lineContent.rightAnglePoints[0].x
                } else if (target.isRight(startGlobalPoint) && 
                    !target.isTop(startGlobalPoint) &&
                    !target.isBottom(startGlobalPoint) && 
                    lineContent.rightAnglePoints[0].x <= lineContent.startPointX) {
                    let newPoint = new Point(lineContent.startPointX + 20, lineContent.startPointY)
                    lineContent.rightAnglePoints.splice(0, 0, newPoint)
                    lineContent.rightAnglePoints[1].x = lineContent.rightAnglePoints[0].x
                } else if (!target.isLeft(startGlobalPoint) && 
                    target.isTop(startGlobalPoint) &&
                    !target.isRight(startGlobalPoint) && 
                    lineContent.rightAnglePoints[0].y >= lineContent.startPointY) {
                    let newPoint = new Point(lineContent.startPointX, lineContent.startPointY - 20)
                    lineContent.rightAnglePoints.splice(0, 0, newPoint)
                    lineContent.rightAnglePoints[1].y = lineContent.rightAnglePoints[0].y
                } else if (!target.isLeft(startGlobalPoint) && 
                    target.isBottom(startGlobalPoint) &&
                    !target.isRight(startGlobalPoint) && 
                    lineContent.rightAnglePoints[0].y <= lineContent.startPointY) {
                    let newPoint = new Point(lineContent.startPointX, lineContent.startPointY + 20)
                    lineContent.rightAnglePoints.splice(0, 0, newPoint)
                    lineContent.rightAnglePoints[1].y = lineContent.rightAnglePoints[0].y
                }
            }
            if (!to.isEmpty() && to.type != MindElementType.NODE_CONNECT_LINE) {
                let endGlobalPoint = new Point(lineContent.endPointX + line.x, lineContent.endPointY + line.y);
                let lastIndex = lineContent.rightAnglePoints.length - 1;
                if (to.isLeft(endGlobalPoint) && 
                    !to.isTop(endGlobalPoint) &&
                    !to.isBottom(endGlobalPoint) && 
                    lineContent.rightAnglePoints[lastIndex].x >= lineContent.endPointX) {
                    let newPoint = new Point(lineContent.endPointX - 20, lineContent.endPointY)
                    lineContent.rightAnglePoints.push(newPoint)
                    if (lastIndex > 0) {
                        lineContent.rightAnglePoints[lastIndex - 1].x = lineContent.rightAnglePoints[lastIndex + 1].x
                    }
                } else if (to.isRight(endGlobalPoint) && 
                    !to.isTop(endGlobalPoint) &&
                    !to.isBottom(endGlobalPoint) && 
                    lineContent.rightAnglePoints[lastIndex].x <= lineContent.endPointX) {
                    let newPoint = new Point(lineContent.endPointX + 20, lineContent.endPointY)
                    lineContent.rightAnglePoints.push(newPoint)
                    if (lastIndex > 0) {
                        lineContent.rightAnglePoints[lastIndex - 1].x = lineContent.rightAnglePoints[lastIndex + 1].x
                    }
                } else if (!to.isLeft(endGlobalPoint) && 
                    to.isTop(endGlobalPoint) &&
                    !to.isRight(endGlobalPoint) && 
                    lineContent.rightAnglePoints[lastIndex].y >= lineContent.endPointY) {
                    let newPoint = new Point(lineContent.endPointX, lineContent.endPointY - 20)
                    lineContent.rightAnglePoints.push(newPoint)
                    if (lastIndex > 0) {
                        lineContent.rightAnglePoints[lastIndex - 1].y = lineContent.rightAnglePoints[lastIndex + 1].y
                    }
                } else if (!to.isLeft(endGlobalPoint) && 
                    to.isBottom(endGlobalPoint) &&
                    !to.isRight(endGlobalPoint) && 
                    lineContent.rightAnglePoints[lastIndex].y <= lineContent.endPointY) {
                    let newPoint = new Point(lineContent.endPointX, lineContent.endPointY + 20)
                    lineContent.rightAnglePoints.push(newPoint)
                    if (lastIndex > 0) {
                        lineContent.rightAnglePoints[lastIndex - 1].y = lineContent.rightAnglePoints[lastIndex + 1].y
                    }
                }
            }
        }
        this.removeRightAnglePoints(line);
        this.supplementPoint(line);
        line.width = Math.abs(lineContent.startPointX - lineContent.endPointX);
        line.height = Math.abs(lineContent.startPointY - lineContent.endPointY);
    }

    rectifyStartAndEndPoint(line) {
        if (line == null || line.isEmpty() || line.lineContent == null) {
            return false
        }
        let lineContent = line.lineContent;
        let rightAnglePointsLength = lineContent.rightAnglePoints.length;
        if (rightAnglePointsLength == 0) {          
            return false
        }
        let target = this.getNodeById(line.parentNodeId).value;
        let to = this.getNodeById(line.lineContent.targetId).value;
        if (target.isEmpty() || to.isEmpty()) {
            return false
        }
        let targetRect = target.getRect()
        let toRect = to.getRect()
        let startPoint = lineContent.startPoint()
        let endPoint = lineContent.endPoint()
        let startGlobalPoint = new Point(lineContent.startPointX + line.x, lineContent.startPointY + line.y);
        let endGlobalPoint = new Point(lineContent.endPointX + line.x, lineContent.endPointY + line.y);
        let fristGlobalPoint = new Point(lineContent.rightAnglePoints[0].x + line.x, lineContent.rightAnglePoints[0].y + line.y);
        // if (1==1) {
        //     return
        // }
        if ((target.type == MindElementType.MAIN_SUBJECT || 
            target.type == MindElementType.SUBJECT || 
            target.type == MindElementType.SON_SUBJECT) && 
            !Util.containsInRectForPoint(targetRect, startGlobalPoint) &&
            Util.isLineIntersectRect(targetRect, startGlobalPoint, fristGlobalPoint)) {
            if (this.pointOnRectLeft(startGlobalPoint, targetRect)) { //起点在左侧
                if (this.equalRightAnglePoint(startPoint.y, lineContent.rightAnglePoints[0].y) && 
                    !this.equalRightAnglePoint(startPoint.x, lineContent.rightAnglePoints[0].x)) { //在一条水平线上
                    let nextPoint = rightAnglePointsLength > 1 ? lineContent.rightAnglePoints[1] : endPoint;
                    if (nextPoint.x == lineContent.rightAnglePoints[0].x && nextPoint.y != lineContent.rightAnglePoints[0].y) {//下一个点和第一个点在一条垂直线上
                        
                        lineContent.rightAnglePoints.splice(0, 0, new Point(startPoint.x - 20, startPoint.y))
                        if (nextPoint.y > lineContent.rightAnglePoints[0].y) { //向下蔓延

                            lineContent.rightAnglePoints.splice(1, 0, new Point(lineContent.rightAnglePoints[0].x, 
                                startPoint.y + (targetRect.y + targetRect.height() - startGlobalPoint.y) + 20));
                            lineContent.rightAnglePoints[2].y = lineContent.rightAnglePoints[1].y
                        } else {
                            lineContent.rightAnglePoints.splice(1, 0, 
                                new Point(lineContent.rightAnglePoints[0].x, 
                                startPoint.y - (startGlobalPoint.y - targetRect.y) - 20));
                            lineContent.rightAnglePoints[2].y = lineContent.rightAnglePoints[1].y
                        }
                    } else if (nextPoint.x != lineContent.rightAnglePoints[0].x && nextPoint.y == lineContent.rightAnglePoints[0].y) {//下一个点和第一个点在一条水平线上
                        lineContent.startPointX = lineContent.startPointX + (targetRect.x - startGlobalPoint.x) * 2 + targetRect.width() //起点移动到右侧
                    }
                }
            } else if (this.pointOnRectRight(startGlobalPoint, targetRect)) { //起点在右侧
                if (this.equalRightAnglePoint(startPoint.y, lineContent.rightAnglePoints[0].y) && 
                    !this.equalRightAnglePoint(startPoint.x, lineContent.rightAnglePoints[0].x)) { //在一条水平线上
                    let nextPoint = rightAnglePointsLength > 1 ? lineContent.rightAnglePoints[1] : endPoint;
                    if (nextPoint.x == lineContent.rightAnglePoints[0].x && nextPoint.y != lineContent.rightAnglePoints[0].y) {//下一个点和第一个点在一条垂直线上
                        
                        lineContent.rightAnglePoints.splice(0, 0, new Point(startPoint.x + 20, startPoint.y))
                        if (nextPoint.y > lineContent.rightAnglePoints[0].y) { //向下蔓延
                            lineContent.rightAnglePoints.splice(1, 0, new Point(lineContent.rightAnglePoints[0].x, 
                                startPoint.y + (targetRect.y + targetRect.height() - startGlobalPoint.y) + 20));
                            lineContent.rightAnglePoints[2].y = lineContent.rightAnglePoints[1].y
                        } else {
                            lineContent.rightAnglePoints.splice(1, 0, 
                                new Point(lineContent.rightAnglePoints[0].x, 
                                startPoint.y - (startGlobalPoint.y - targetRect.y) - 20));
                            lineContent.rightAnglePoints[2].y = lineContent.rightAnglePoints[1].y
                        }
                    } else if (nextPoint.x != lineContent.rightAnglePoints[0].x && nextPoint.y == lineContent.rightAnglePoints[0].y) {//下一个点和第一个点在一条水平线上
                        lineContent.startPointX = lineContent.startPointX - (startGlobalPoint.x - targetRect.x - targetRect.width()) * 2 + targetRect.width() //起点移动到右侧
                    }
                }
            } else if (targetRect.y > startPoint.y) { //起点在上侧

            } else if (targetRect.y + targetRect.height() < startPoint.y) { //起点在下侧

            }
        }
        rightAnglePointsLength = lineContent.rightAnglePoints.length
        startPoint = lineContent.startPoint()
        endPoint = lineContent.endPoint()
        startGlobalPoint = new Point(lineContent.startPointX + line.x, lineContent.startPointY + line.y);
        endGlobalPoint = new Point(lineContent.endPointX + line.x, lineContent.endPointY + line.y);
        let rightEndPoint = new Point(lineContent.rightAnglePoints[rightAnglePointsLength - 1].x, lineContent.rightAnglePoints[rightAnglePointsLength - 1].y);
        let rightEndGlobalPoint = new Point(rightEndPoint.x + line.x, rightEndPoint.y + line.y);

        if ((to.type == MindElementType.MAIN_SUBJECT || 
            to.type == MindElementType.SUBJECT || 
            to.type == MindElementType.SON_SUBJECT) && 
            !Util.containsInRectForPoint(toRect, endGlobalPoint) &&
            Util.isLineIntersectRect(toRect, endGlobalPoint, rightEndGlobalPoint)) {
            if (this.pointOnRectLeft(endGlobalPoint, toRect)) { //终点在左侧
                if (this.equalRightAnglePoint(endPoint.y, rightEndPoint.y) && !this.equalRightAnglePoint(endPoint.x, rightEndPoint.x)) { //在一条水平线上
                    let previousPoint = rightAnglePointsLength > 1 ? lineContent.rightAnglePoints[rightAnglePointsLength - 2] : startPoint;
                    if (previousPoint.x == rightEndPoint.x && previousPoint.y != rightEndPoint.y) {//上一个点和最后一个点在一条垂直线上
                        lineContent.rightAnglePoints.push(new Point(endPoint.x - 20, endPoint.y))
                        if (previousPoint.y > endPoint.y) { //向下蔓延

                            lineContent.rightAnglePoints.splice(lineContent.rightAnglePoints.length - 1, 0, 
                                new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, 
                                endPoint.y + (toRect.y + toRect.height() - endGlobalPoint.y) + 20));

                            lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 3].y = lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 2].y
                        } else {
                            lineContent.rightAnglePoints.splice(lineContent.rightAnglePoints.length - 1, 0, 
                                new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, 
                                endPoint.y - (endGlobalPoint.y - toRect.y) - 20));
                            lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 3].y = lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 2].y

                        }
                    } else if (previousPoint.x != rightEndPoint.x && previousPoint.y == rightEndPoint.y) {//下一个点和最后一个点在一条水平线上
                        lineContent.endPointX = lineContent.endPointX + (endGlobalPoint.x - toRect.x) * 2 + toRect.width() //终点移动到右侧
                    }
                }
            } else if (this.pointOnRectRight(endGlobalPoint, toRect)) { //终点在右侧
                if (this.equalRightAnglePoint(endPoint.y, rightEndPoint.y) && !this.equalRightAnglePoint(endPoint.x, rightEndPoint.x)) { //在一条水平线上
                    let previousPoint = rightAnglePointsLength > 1 ? lineContent.rightAnglePoints[rightAnglePointsLength - 2] : startPoint;
                    if (previousPoint.x == rightEndPoint.x && previousPoint.y != rightEndPoint.y) {//上一个点和最后一个点在一条垂直线上
                        lineContent.rightAnglePoints.push(new Point(endPoint.x + 20, endPoint.y))
                        if (previousPoint.y > endPoint.y) { //向下蔓延

                            lineContent.rightAnglePoints.splice(lineContent.rightAnglePoints.length - 1, 0, 
                                new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, 
                                endPoint.y + (toRect.y + toRect.height() - endGlobalPoint.y) + 20));

                            lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 3].y = lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 2].y
                        } else {
                            lineContent.rightAnglePoints.splice(lineContent.rightAnglePoints.length - 1, 0, 
                                new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, 
                                endPoint.y - (endGlobalPoint.y - toRect.y) - 20));
                            lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 3].y = lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 2].y

                        }
                    } else if (previousPoint.x != rightEndPoint.x && previousPoint.y == rightEndPoint.y) {//下一个点和最后一个点在一条水平线上
                        lineContent.endPointX = lineContent.endPointX - (endGlobalPoint.x - toRect.x) * 2 - toRect.width() //终点移动到左侧
                    }
                }
            } else if (targetRect.y > startPoint.y) { //终点在上侧

            } else if (targetRect.y + targetRect.height() < startPoint.y) { //终点在下侧

            }
        }
    }

    movePoint(moveX, moveY, start, end) {

    }
    
    supplementPoint(line) {
        if (line == null || line.isEmpty() || line.lineContent == null) {
            return false
        }
        
        let lineContent = line.lineContent;
        let rightAnglePointsLength = lineContent.rightAnglePoints.length;

        if (rightAnglePointsLength == 0 || rightAnglePointsLength > 10) {          
            return false
        }
        let startPoint = lineContent.startPoint()
        let endPoint = lineContent.endPoint()
        let previousPoint = startPoint
        let nextPoint = endPoint
        
        for (let j = 0; j < rightAnglePointsLength; j++) {
            if (rightAnglePointsLength > 1 && j < rightAnglePointsLength - 1) {
                let point1 = j == 0 ? startPoint : lineContent.rightAnglePoints[j];
                let point4 = j == (rightAnglePointsLength - 2) ? endPoint : lineContent.rightAnglePoints[j + 2];
                let list = this.supplementPointZShape(point1, lineContent.rightAnglePoints[j], lineContent.rightAnglePoints[j + 1], point4);      
                if (list.length > 0) {                    
                    if (list.length == 1) {
                        lineContent.rightAnglePoints[j] = list[0];
                        lineContent.rightAnglePoints.splice(j + 1, 1);
                    } else if (list.length == 2) {
                        lineContent.rightAnglePoints[j] = list[0];
                        lineContent.rightAnglePoints[j + 1] = list[1];
                    } else {
                        for (let i = 0; i < list.length; i++) {
                            const cell = list[i];
                            if (i == 0) {
                                lineContent.rightAnglePoints[j] = cell;
                            } else if (i < list.length - 1) {
                                lineContent.rightAnglePoints.splice(j + i, 0, cell);
                            } else {
                                lineContent.rightAnglePoints[j + i] = cell
                            }
                        }
                    }
                    this.supplementPoint(line)
                    return true
                }
            }
            if (j == 0) {
                previousPoint = startPoint
                nextPoint = rightAnglePointsLength == 1 ? endPoint : lineContent.rightAnglePoints[j + 1]
            } else if (j == rightAnglePointsLength - 1) {
                previousPoint = lineContent.rightAnglePoints[j - 1]
                nextPoint = endPoint
            } else {
                previousPoint = lineContent.rightAnglePoints[j - 1]
                nextPoint = lineContent.rightAnglePoints[j + 1]
            }
            let x = lineContent.rightAnglePoints[j].x
            let y = lineContent.rightAnglePoints[j].y

            // if (!this.equalRightAnglePoint(x, previousPoint.x) && !this.equalRightAnglePoint(y, previousPoint.y) && this.equalRightAnglePoint(x, nextPoint.x)) {
            //     lineContent.rightAnglePoints.splice(j + 1, 0, new Point(Math.min(x, previousPoint.x) + Math.abs(x - previousPoint.x) / 2 , y))
            //     lineContent.rightAnglePoints.splice(j + 2, 0, new Point(Math.min(x, previousPoint.x) + Math.abs(x - previousPoint.x) / 2 , nextPoint.y))
            //     this.supplementPoint(line)
            //     return true
            // }
            // if (!this.equalRightAnglePoint(x, previousPoint.x) && !this.equalRightAnglePoint(y, previousPoint.y) && this.equalRightAnglePoint(y, nextPoint.y)) {
            //     lineContent.rightAnglePoints.splice(j + 1, 0, new Point(x , Math.min(y, previousPoint.y) + Math.abs(y - previousPoint.y) / 2))
            //     lineContent.rightAnglePoints.splice(j + 2, 0, new Point(nextPoint.x , Math.min(y, previousPoint.y) + Math.abs(y - previousPoint.y) / 2))
            //     this.supplementPoint(line)
            //     return true
            // }

            // if (!this.equalRightAnglePoint(x, nextPoint.x) && !this.equalRightAnglePoint(y, nextPoint.y) && 
            //     this.equalRightAnglePoint(x, previousPoint.x)) {
            //     lineContent.rightAnglePoints.splice(j + 1, 0, new Point(Math.min(x, nextPoint.x) + Math.abs(x - nextPoint.x) / 2 , y))
            //     lineContent.rightAnglePoints.splice(j + 2, 0, new Point(Math.min(x, nextPoint.x) + Math.abs(x - nextPoint.x) / 2 , previousPoint.y))
            //     this.supplementPoint(line)
            //     return true
            // }
            // if (!this.equalRightAnglePoint(x, nextPoint.x) && !this.equalRightAnglePoint(y, nextPoint.y) && 
            //     this.equalRightAnglePoint(y, previousPoint.y)) {
            //     lineContent.rightAnglePoints.splice(j + 1, 0, new Point(x , Math.min(y, nextPoint.y) + Math.abs(y - nextPoint.y) / 2))
            //     lineContent.rightAnglePoints.splice(j + 2, 0, new Point(previousPoint.x , Math.min(y, nextPoint.y) + Math.abs(y - nextPoint.y) / 2))
            //     this.supplementPoint(line)
            //     return true
            // }
        }
        return false
    }

    supplementPointZShape(point1, point2, point3, point4) {
        var list = new Array()
        if (this.equalRightAnglePoint(point1.y, point2.y) && !this.equalRightAnglePoint(point1.x, point2.x) && 
            !this.equalRightAnglePoint(point2.y, point3.y) && !this.equalRightAnglePoint(point2.x, point3.x) && 
            this.equalRightAnglePoint(point3.y, point4.y) && !this.equalRightAnglePoint(point3.x, point4.x)) { //水平Z型
            if (point1.y < point4.y) { //起点在顶部
                if (point1.x < point2.x) { //正向Z
                    if (point2.x > point3.x) { //Z第一个角度小于90度
                        if (point3.x < point4.x) { //Z第二个角度小于90度
                            if (point1.x < point4.x) { //重置点2和点3
                                let x = Math.min(point1.x, point4.x) + Math.abs(point1.x - point4.x) / 2;
                                list.push(new Point(x, point1.y))
                                list.push(new Point(x, point4.y))
                            } else { //增加两个点消除Z
                                list.push(point2)
                                let y = Math.min(point2.y, point3.y) + Math.abs(point2.y - point3.y) / 2;
                                list.push(new Point(point2.x, y))
                                list.push(new Point(point3.x, y))
                                list.push(point3)
                            }
                        } else { //Z第二个角度大于90度
                            list.push(point2)
                            list.push(new Point(point2.x, point3.y))
                        }
                        return list;
                    } else {
                        if (point3.x < point4.x) { //Z第一、二个角度大于90度
                            list.push(point2);
                            list.push(new Point(point2.x, point3.y));
                            return list;
                        } else if (point2.x > point4.x) {
                            list.push(point2);
                            list.push(new Point(point2.x, point3.y));
                        } else {                            
                            list.push(new Point(point3.x, point2.y));
                            list.push(point3);
                        }
                        return list;
                    }
                } else { //反向Z
                    if (point2.x < point3.x) { //Z第一个角度小于90度
                        if (point3.x > point4.x) { //Z第二个角度小于90度
                            if (point1.x > point4.x) { //重置点2和点3
                                let x = Math.min(point1.x, point4.x) + Math.abs(point1.x - point4.x) / 2;
                                list.push(new Point(x, point1.y))
                                list.push(new Point(x, point4.y))
                            } else { //增加两个点消除Z
                                list.push(point2)
                                let y = Math.min(point2.y, point3.y) + Math.abs(point2.y - point3.y) / 2;
                                list.push(new Point(point2.x, y))
                                list.push(new Point(point3.x, y))
                                list.push(point3)
                            }
                        } else { //Z第二个角度大于90度
                            list.push(point2)
                            list.push(new Point(point2.x, point3.y))
                        }
                        return list;
                    } else {
                        if (point3.x > point4.x) { //Z第一、二个角度大于90度
                            list.push(point2);
                            list.push(new Point(point2.x, point3.y));
                            return list;
                        } else if (point2.x < point4.x) {
                            list.push(point2);
                            list.push(new Point(point2.x, point3.y));
                        } else {                            
                            list.push(new Point(point3.x, point2.y));
                            list.push(point3);
                        }
                        return list;
                    }
                }
            } else { //起点在低部
                if (point1.x > point2.x) { //正向Z
                    if (point2.x < point3.x) { //Z第一个角度小于90度
                        if (point3.x > point4.x) { //Z第二个角度小于90度
                            if (point1.x > point4.x) { //重置点2和点3
                                let x = Math.min(point1.x, point4.x) + Math.abs(point1.x - point4.x) / 2;
                                list.push(new Point(x, point1.y))
                                list.push(new Point(x, point4.y))
                            } else { //增加两个点消除Z
                                list.push(point2)
                                let y = Math.min(point2.y, point3.y) + Math.abs(point2.y - point3.y) / 2;
                                list.push(new Point(point2.x, y))
                                list.push(new Point(point3.x, y))
                                list.push(point3)
                            }
                        } else { //Z第二个角度大于90度
                            list.push(point2)
                            list.push(new Point(point2.x, point3.y))
                        }
                        return list;
                    } else {
                        if (point3.x > point4.x) { //Z第一、二个角度大于90度
                            list.push(point2);
                            list.push(new Point(point2.x, point3.y));
                            return list;
                        } else if (point2.x < point4.x) {
                            list.push(point2);
                            list.push(new Point(point2.x, point3.y));
                        } else {                            
                            list.push(new Point(point3.x, point2.y));
                            list.push(point3);
                        }
                        return list;
                    }
                } else { //反向Z
                    if (point2.x > point3.x) { //Z第一个角度小于90度
                        if (point3.x < point4.x) { //Z第二个角度小于90度
                            if (point1.x < point4.x) { //重置点2和点3
                                let x = Math.min(point1.x, point4.x) + Math.abs(point1.x - point4.x) / 2;
                                list.push(new Point(x, point1.y))
                                list.push(new Point(x, point4.y))
                            } else { //增加两个点消除Z
                                list.push(point2)
                                let y = Math.min(point2.y, point3.y) + Math.abs(point2.y - point3.y) / 2;
                                list.push(new Point(point2.x, y))
                                list.push(new Point(point3.x, y))
                                list.push(point3)
                            }
                        } else { //Z第二个角度大于90度
                            list.push(point2)
                            list.push(new Point(point2.x, point3.y))
                        }
                        return list;
                    } else {
                        if (point3.x < point4.x) { //Z第一、二个角度大于90度
                            list.push(point2);
                            list.push(new Point(point2.x, point3.y));
                            return list;
                        } else if (point2.x > point4.x) {
                            list.push(point2);
                            list.push(new Point(point2.x, point3.y));
                        } else {                            
                            list.push(new Point(point3.x, point2.y));
                            list.push(point3);
                        }
                        return list;
                    }
                }
            }
        }
        return []
    }

    equalRightAnglePoint(value1, value2) {        
        return Math.abs(value1 - value2) < 3
    }

    removeRightAnglePoints(line) {
        if (line == null || line.isEmpty() || line.lineContent == null) {
            return
        }
        let lineContent = line.lineContent;
        if (lineContent.rightAnglePoints.length > 0) { // 移除重复点
            let newPightAnglePoints = new Array()
            let startPoint = lineContent.startPoint()
            let endPoint = lineContent.endPoint()
            for (let index = 0; index < lineContent.rightAnglePoints.length; index++) {
                const cell = lineContent.rightAnglePoints[index];
                if (Util.getPointSpacing(startPoint, cell) < 3) {
                    continue
                }
                if (Util.getPointSpacing(endPoint, cell) < 3) {
                    continue
                }
                if (index == 0 || index == lineContent.rightAnglePoints.length - 1) {
                    newPightAnglePoints.push(cell);
                } else {
                    let per = lineContent.rightAnglePoints[index - 1];
                    if (Util.getPointSpacing(per, cell) < 6) { //重复点
                        continue
                    } else {
                        newPightAnglePoints.push(cell);
                    }
                }
            }
            lineContent.rightAnglePoints = newPightAnglePoints;
        }
        if (lineContent.rightAnglePoints.length > 1) { //移除一条线上中间点
            let startPoint = lineContent.startPoint()
            let endPoint = lineContent.endPoint()
            let newPightAnglePoints = new Array()                
            for (let index = 0; index < lineContent.rightAnglePoints.length; index++) {
                const cell = lineContent.rightAnglePoints[index];
                if (index == lineContent.rightAnglePoints.length - 1 && newPightAnglePoints.length == 0) {
                    newPightAnglePoints.push(cell);
                    continue
                }
                let per = null;
                let next = index < lineContent.rightAnglePoints.length - 1 ? lineContent.rightAnglePoints[index + 1] : endPoint; 

                if (index == 0) {
                    per = startPoint
                } else if (newPightAnglePoints.length > 0) {
                    per = newPightAnglePoints[newPightAnglePoints.length - 1]
                } else {
                    per = lineContent.rightAnglePoints[index - 1];
                }
                if ((per.y == cell.y && next.y == cell.y)) { //水平中间点                    
                    continue
                } else if ((per.x == cell.x && next.x == cell.x)) { //垂直中间点
                    continue
                } else {
                    newPightAnglePoints.push(cell);
                }
                // if (index == 0) {
                //     newPightAnglePoints.push(cell);
                // } else if (index == lineContent.rightAnglePoints.length - 1) {
                //     newPightAnglePoints.push(cell);
                // } else {
                //     let per = lineContent.rightAnglePoints[index - 1];
                //     let next = lineContent.rightAnglePoints[index + 1];
                //     if ((per.y == cell.y && next.y == cell.y)) { //水平中间点
                //         continue
                //     } else if ((per.x == cell.x && next.x == cell.x)) { //垂直中间点
                //         continue
                //     } else {
                //         newPightAnglePoints.push(cell);
                //     }
                // }
            }
            lineContent.rightAnglePoints = newPightAnglePoints;
        }

        if (lineContent.rightAnglePoints.length > 1) { //移除移除回旋点：121
            for (let j = 0; j < 5; j++) {
                var newPightAnglePoints = []
                newPightAnglePoints.push((lineContent.startPointX, lineContent.startPointY))
                for (let index = 0; index < lineContent.rightAnglePoints.length; index++) {
                    newPightAnglePoints.push(lineContent.rightAnglePoints[index])
                }
                newPightAnglePoints.push((lineContent.endPointX, lineContent.endPointY))
                let list = this.removeConvolutePoint(newPightAnglePoints)
                if (list.length < newPightAnglePoints.length && list.length > 1) {
                    lineContent.rightAnglePoints = []
                    for (let i = 1; i < (list.length - 1); i++) {
                        lineContent.rightAnglePoints.push(list[i])
                    }
                }
            }
        }

        if (lineContent.rightAnglePoints.length > 2) { // 
            let removeList = []                          
            for (let index = 0; index < lineContent.rightAnglePoints.length; index++) { //查找不构成不构成直角的点，进行修正和移除
                const cell = lineContent.rightAnglePoints[index];                
                if (index == 0 || index == lineContent.rightAnglePoints.length - 1) {
                    continue;
                } else {
                    let per = lineContent.rightAnglePoints[index - 1];
                    let next = lineContent.rightAnglePoints[index + 1];
                    if (cell.x != per.x && cell.y != per.y) {
                        if (cell.x == next.x) {
                            cell.y = per.y
                        } else if (cell.y == next.y) {
                            cell.x = per.x
                        } else {
                            removeList.push(cell)
                        }
                    }
                }
            }
            if (removeList.length > 0) {
                let newPightAnglePoints = new Array()
                for (let index = 0; index < lineContent.rightAnglePoints.length; index++) {
                    const cell = lineContent.rightAnglePoints[index];
                    let isRemove = false
                    for (let j = 0; j < removeList.length; j++) {
                        if (cell.x == removeList[j].x && 
                            cell.y == removeList[j].y) {
                            isRemove = true;
                            break
                        }
                    }
                    if (!isRemove) {
                        newPightAnglePoints.push(cell)
                    }
                }
                lineContent.rightAnglePoints = newPightAnglePoints;
            }
        }
        if (lineContent.rightAnglePoints.length == 0) {//补点
            if (lineContent.startPointX == lineContent.endPointX) {
                if (lineContent.startPointY < lineContent.endPointY) {
                    lineContent.rightAnglePoints.push(new Point(lineContent.startPointX, lineContent.startPointY + Math.floor((lineContent.endPointY - lineContent.startPointY) * 0.33)))
                    lineContent.rightAnglePoints.push(new Point(lineContent.startPointX, lineContent.startPointY + Math.floor((lineContent.endPointY - lineContent.startPointY) * 0.66)))
                } else {
                    lineContent.rightAnglePoints.push(new Point(lineContent.startPointX, lineContent.endPointY + Math.floor((lineContent.startPointY - lineContent.endPointY) * 0.33)))
                    lineContent.rightAnglePoints.push(new Point(lineContent.startPointX, lineContent.endPointY + Math.floor((lineContent.startPointY - lineContent.endPointY) * 0.66)))
                }
            } else if (lineContent.startPointY == lineContent.endPointY) {
                if (lineContent.startPointX < lineContent.endPointX) {
                    lineContent.rightAnglePoints.push(new Point(lineContent.startPointX + Math.floor((lineContent.endPointX - lineContent.startPointX) * 0.33), lineContent.startPointY))
                    lineContent.rightAnglePoints.push(new Point(lineContent.startPointX + Math.floor((lineContent.endPointX - lineContent.startPointX) * 0.66), lineContent.startPointY))
                } else {
                    lineContent.rightAnglePoints.push(new Point(lineContent.endPointX + Math.floor((lineContent.startPointX - lineContent.endPointX) * 0.33), lineContent.startPointY))
                    lineContent.rightAnglePoints.push(new Point(lineContent.endPointX + Math.floor((lineContent.startPointX - lineContent.endPointX) * 0.66), lineContent.startPointY))
                }
            } else {
                if (Math.abs(lineContent.startPointX - lineContent.endPointX) < Math.abs(lineContent.startPointY - lineContent.endPointY)) {
                    lineContent.rightAnglePoints.push(new Point(lineContent.startPointX, lineContent.endPointY))
                } else {
                    lineContent.rightAnglePoints.push(new Point(lineContent.endPointX, lineContent.startPointY))
                }
            }
        }
    }

    removeConvolutePoint(list) {
        if (list.length > 2) { //移除移除回旋点：121
            var newPightAnglePoints = []
            var hit = false;
            newPightAnglePoints.push(list[0])
            for (let index = 1; index < (list.length - 1); index++) {
                let cell = list[index];
                if (hit) {
                    newPightAnglePoints.push(cell)
                } else {
                    let per = list[index - 1];
                    let next = list[index + 1];
                    if ((per.y == cell.y && next.y == cell.y)) { //三点水平
                        if (cell.x < per.x && cell.x < next.x && next.x < per.x) {//左侧回
                            hit = true;
                            break;
                        } else if (cell.x > per.x && cell.x > next.x && next.x > per.x) {//右侧回旋
                            hit = true;
                            break;
                        } else {
                            newPightAnglePoints.push(cell)
                        }
                    } else if ((per.x == cell.x && next.x == cell.x)) { //三点垂直
                        if (cell.y < per.y && cell.y < next.y && next.y < per.y) {//上侧回旋
                            hit = true;
                            break;
                        } else if (cell.y > per.y && cell.y > next.y && next.y > per.y) {//下侧回旋
                            hit = true;
                            break;
                        } else {
                            newPightAnglePoints.push(cell)
                        }
                    } else {
                        newPightAnglePoints.push(cell)
                    }
                }
            }
            newPightAnglePoints.push(list[list.length-1])
            return newPightAnglePoints
        }
        
        return list
    }

    getDataConnectLine(data1, data2) {
        let list = []
        if (data1 == null || data1.isEmpty() ||
            data2 == null || data2.isEmpty()) {
            return list;
        }
        let keys = this.nodeConnectLineDataDict.keys();
        let length = keys.length
        for (let index = 0; index < length; index++) {
            let line = this.nodeConnectLineDataDict.get(keys[index]);
            if (line == null || line.isEmpty() || line.lineContent == null) {
                continue
            }
            if ((line.parentNodeId == data1.id && line.lineContent.targetId == data2.id) ||
                (line.parentNodeId == data2.id && line.lineContent.targetId == data1.id)) {
                list.push(line);
            }
        }
        return list;
    }

    setConnectLine(line, isCheckControlPoint = true, targetDirection = FlowChartDirection.UNKNOWN, toDirection = FlowChartDirection.UNKNOWN) {
        if (line == null || line.isEmpty()) {
            return
        }
        let target = this.getNodeById(line.parentNodeId).value;
        let to = this.getNodeById(line.lineContent.targetId).value;
        if (target.isEmpty() || to.isEmpty()) {
            return
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return;
        }
        this.initConnectLineControlPoint(line, target, to, isCheckControlPoint);        
        
        if (line.x <= 0 || line.y <= 0) {
            let startControlPoint = lineContent.getStartControlPoint();
            let targetCenterPoint = target.getCenterPoint()
            let toCenterPoint = to.getCenterPoint()
            let margin = lineContent.getLineStartMargin();
            let targetI = null
            
            if (target.type == MindElementType.NODE_CONNECT_LINE) {
                let points = []
                points.push(new CGPoint(target.x + target.lineContent.startPointX,
                                        target.y + target.lineContent.startPointY));                
                if (target.lineContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
                    for (let index = 0; index < target.lineContent.rightAnglePoints.length; index++) {
                        const cellPoint = target.lineContent.rightAnglePoints[index];
                        points.push(new CGPoint(target.x + cellPoint.x, target.y + cellPoint.y));
                    }
                }
                points.push(new CGPoint(target.x + target.lineContent.endPointX,
                                        target.y + target.lineContent.endPointY));

                targetI = points[0]; 
                let minSpace = 9999999;
                for (let index = 0; index < points.length; index++) {
                    const cellPoint = points[index];
                    if (index > 0 && target.lineContent.connectLineType != ConnectLineType.CURVE_LINE) {
                        let foot = Util.getStaticgetLineIntersectionVerticalPoint(points[index], points[index - 1], toCenterPoint);
                        let d = Util.getPointSpacing(points[index], points[index - 1]);
                        let d1 = Util.getPointSpacing(points[index], foot);
                        let d2 = Util.getPointSpacing(points[index - 1], foot);
                        if (d1 < d && d2 < d) {
                            let space = Util.getPointSpacing(foot, toCenterPoint)
                            if (space < minSpace) {
                                targetI = foot;
                                minSpace = space;
                            }
                        } else {
                            let space = Util.getPointSpacing(cellPoint, toCenterPoint)
                            if (space < minSpace) {
                                targetI = cellPoint;
                                minSpace = space;
                            }
                        }
                    } else {
                        let space = Util.getPointSpacing(cellPoint, toCenterPoint)
                        if (space < minSpace) {
                            targetI = cellPoint;
                            minSpace = space;
                        }
                    }
                    
                }
            } else if (lineContent.connectLineType == ConnectLineType.CURVE_LINE || 
                lineContent.connectLineType == ConnectLineType.STRAIGHT_ARROW_LINE ||
                lineContent.connectLineType == ConnectLineType.STRAIGHT_CIRCULAR_LINE ) {
                targetI = target.getRightAngleShapeHitPoint(startControlPoint, margin);
            } else {
                if (targetDirection == FlowChartDirection.LEFT) {
                    targetI = target.hitPoint(new CGPoint(target.x, target.y + target.height/2), lineContent.getLineStartMargin()); 
                } else if (targetDirection == FlowChartDirection.RIGHT) {
                    targetI = target.hitPoint(new CGPoint(target.x + target.width, target.y + target.height/2), lineContent.getLineStartMargin()); 
                } else if (targetDirection == FlowChartDirection.TOP) {
                    targetI = target.hitPoint(new CGPoint(target.x + target.width/2, target.y), lineContent.getLineStartMargin()); 
                } else if (targetDirection == FlowChartDirection.BOTTOM) {
                    targetI = target.hitPoint(new CGPoint(target.x + target.width/2, target.y + target.height), lineContent.getLineStartMargin());
                }
                if (targetDirection == FlowChartDirection.UNKNOWN || targetI.x <= 0 || targetI.y <= 0) {
                    targetI = this.getInitLineTargetI(line, target, to)
                }
            }

            margin = lineContent.getLineEndMargin();
            let endControlPoint = lineContent.getEndControlPoint();
            let toI = null;
            if (to.type == MindElementType.NODE_CONNECT_LINE) {
                let points = []
                points.push(new CGPoint(to.x + to.lineContent.startPointX, to.y + to.lineContent.startPointY));                
                if (to.lineContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
                    for (let index = 0; index < to.lineContent.rightAnglePoints.length; index++) {
                        const cellPoint = to.lineContent.rightAnglePoints[index];
                        points.push(new CGPoint(to.x + cellPoint.x, to.y + cellPoint.y));
                    }
                }
                points.push(new CGPoint(to.x + to.lineContent.endPointX, to.y + to.lineContent.endPointY));

                toI = points[0]; 
                let minSpace = 9999999;
                for (let index = 0; index < points.length; index++) {
                    const cellPoint = points[index];
                    if (index > 0 && to.lineContent.connectLineType != ConnectLineType.CURVE_LINE) {
                        let foot = Util.getStaticgetLineIntersectionVerticalPoint(points[index], points[index - 1], targetI);
                        let d = Util.getPointSpacing(points[index], points[index - 1]);
                        let d1 = Util.getPointSpacing(points[index], foot);
                        let d2 = Util.getPointSpacing(points[index - 1], foot);
                        if (d1 < d && d2 < d) {
                            let space = Util.getPointSpacing(foot, targetI)
                            if (space < minSpace) {
                                toI = foot;
                                minSpace = space;
                            }
                        } else {
                            let space = Util.getPointSpacing(cellPoint, targetI)
                            if (space < minSpace) {
                                toI = cellPoint;
                                minSpace = space;
                            }
                        }
                    } else {
                        let space = Util.getPointSpacing(cellPoint, targetI)
                        if (space < minSpace) {
                            toI = cellPoint;
                            minSpace = space;
                        }
                    }
                }
            } else if (lineContent.connectLineType == ConnectLineType.CURVE_LINE || 
                lineContent.connectLineType == ConnectLineType.STRAIGHT_ARROW_LINE ||
                lineContent.connectLineType == ConnectLineType.STRAIGHT_CIRCULAR_LINE ) {
                toI = to.getRightAngleShapeHitPoint(endControlPoint, margin);
            } else {
                if (toDirection == FlowChartDirection.LEFT) {
                    toI = to.hitPoint(new CGPoint(to.x, to.y + to.height/2), lineContent.getLineEndMargin()); 
                } else if (toDirection == FlowChartDirection.RIGHT) {
                    toI = to.hitPoint(new CGPoint(to.x + to.width, to.y + to.height/2), lineContent.getLineEndMargin()); 
                } else if (toDirection == FlowChartDirection.TOP) {
                    toI = to.hitPoint(new CGPoint(to.x + to.width/2, to.y), lineContent.getLineEndMargin()); 
                } else if (toDirection == FlowChartDirection.BOTTOM) {
                    toI = to.hitPoint(new CGPoint(to.x + to.width/2, to.y + to.height), lineContent.getLineEndMargin());
                }
                if (toDirection == FlowChartDirection.UNKNOWN || toI.x <= 0 || toI.y <= 0) {
                    toI = this.getInitLineToI(line, target, to)
                }
            }
            line.x = Math.min(targetI.x, toI.x);
            line.y = Math.min(targetI.y, toI.y);
    
            let startControlPointInLine = new CGPoint(startControlPoint.x - (line.x),
                       startControlPoint.y - (line.y));
            let endControlPointInLine = new CGPoint(endControlPoint.x - (line.x),
                    endControlPoint.y - (line.y));
            let startPoint = new CGPoint(Math.abs((targetI.x) - line.x), Math.abs((targetI.y) - line.y));
            let endPoint = new CGPoint(Math.abs((toI.x) - line.x), Math.abs((toI.y) - line.y));
    
            lineContent.startPointX = Math.abs((targetI.x) - line.x);
            lineContent.startPointY = Math.abs((targetI.y) - line.y);
            lineContent.endPointX = Math.abs((toI.x) - line.x);
            lineContent.endPointY = Math.abs((toI.y) - line.y);
            if (target.type == MindElementType.NODE_CONNECT_LINE &&
                (lineContent.startShape == LinePointShape.ARROW ||
                lineContent.startShape == LinePointShape.BAMBOO_ARROW ||
                lineContent.startShape == LinePointShape.SIMPLE_ARROW ||
                lineContent.startShape == LinePointShape.T_SHAPED)) {
                arrowH = 0.0
                if (lineContent.startShape == LinePointShape.ARROW || lineContent.startShape == LinePointShape.BAMBOO_ARROW) {
                    arrowH = new UiUtil().getArrowWidth(new Point( 0, 0), new Point( 0, 200), 
                            new UiUtil().dip2px(lineContent.lineWidth)) + new UiUtil().dip2px(4)
                } else if (lineContent.startShape == LinePointShape.SIMPLE_ARROW) {
                    arrowH = new UiUtil().dip2px(4);
                } else {
                    arrowH = (new UiUtil().dip2px(lineContent.lineWidth)/2 + 
                            new UiUtil().dip2px(target.lineContent.lineWidth)/2) + new UiUtil().dip2px(4);
                }
                var angle = 0;
                var distance = 0;
                var point
                switch (lineContent.connectLineType) {
                    case ConnectLineType.STRAIGHT_ARROW_LINE:
                    case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                        angle = Util.getCircleDegreesInPoint(endPoint, startPoint);
                        distance = Util.getPointSpacing(endPoint, startPoint);
                        distance = distance - arrowH;
                        point = Util.getCirclePoint(endPoint, angle, distance);
                        lineContent.startPointX = (point.x);
                        lineContent.startPointY = (point.y);
                        break;
                    case ConnectLineType.CURVE_LINE:
                        angle = Util.getCircleDegreesInPoint(startControlPointInLine, startPoint);
                        distance = Util.getPointSpacing(startControlPointInLine, startPoint);
                        distance = distance - arrowH;
                        point = Util.getCirclePoint(startControlPointInLine, angle, distance);
                        lineContent.startPointX = (point.x);
                        lineContent.startPointY = (point.y);
                        break;
                    case ConnectLineType.RIGHT_ANGLE_LINE:
                        if (lineContent.rightAnglePoints.length > 0) {
                            let point0 = new CGPoint(lineContent.rightAnglePoints[0].x, lineContent.rightAnglePoints[0].y);
                            let angle = Util.getCircleDegreesInPoint(point0, startPoint);
                            distance = Util.getPointSpacing(point0, startPoint);
                            distance = distance - arrowH;
                            point = Util.getCirclePoint(point0, angle, distance);
                            lineContent.startPointX = (point.x);
                            lineContent.startPointY = (point.y);
                        }
                    break;
                    default:
                }
            }
            if (to.type == MindElementType.NODE_CONNECT_LINE &&
                (lineContent.endShape == LinePointShape.ARROW ||
                lineContent.endShape == LinePointShape.BAMBOO_ARROW ||
                lineContent.endShape == LinePointShape.SIMPLE_ARROW ||
                lineContent.endShape == LinePointShape.T_SHAPED)) {
                arrowH = 0;
                if (lineContent.endShape == LinePointShape.ARROW || lineContent.endShape == LinePointShape.BAMBOO_ARROW) {
                    arrowH = new UiUtil().getArrowWidth(new Point( 0, 0), new Point( 0, 200), 
                            new UiUtil().dip2px(lineContent.lineWidth)) + new UiUtil().dip2px(4)
                }else if (lineContent.endShape == LinePointShape.SIMPLE_ARROW) {
                    arrowH = new UiUtil().dip2px(4);
                } else {
                    arrowH = (new UiUtil().dip2px(lineContent.lineWidth)/2 + 
                            new UiUtil().dip2px(to.lineContent.lineWidth)/2) + new UiUtil().dip2px(4);
                }
                var angle = 0;
                var distance = 0;
                var point
                switch (lineContent.connectLineType) {
                    case ConnectLineType.STRAIGHT_ARROW_LINE:
                    case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                        angle = Util.getCircleDegreesInPoint(startPoint, endPoint);
                        distance = Util.getPointSpacing(endPoint, startPoint);
                        distance = distance - arrowH;
                        point = Util.getCirclePoint(startPoint, angle, distance);
                        lineContent.endPointX = (point.x);
                        lineContent.endPointY = (point.y);
                        break;
                    case ConnectLineType.CURVE_LINE:
                        angle = Util.getCircleDegreesInPoint(endControlPointInLine, endPoint);
                        distance = Util.getPointSpacing(endControlPointInLine, endPoint);
                        distance = distance - arrowH;
                        point = Util.getCirclePoint(endControlPointInLine, angle, distance);
                        lineContent.endPointX = (point.x);
                        lineContent.endPointY = (point.y);
                        break;
                    case ConnectLineType.RIGHT_ANGLE_LINE:
                        let count = lineContent.rightAnglePoints.length;
                        var point0;
                        if (count > 0) {
                            point0 = new CGPoint(lineContent.rightAnglePoints[count - 1].x, lineContent.rightAnglePoints[count - 1].y);
                        } else {
                            point0 = startPoint;
                        }
                        angle = Util.getCircleDegreesInPoint(point0, endPoint);
                        distance = Util.getPointSpacing(point0, endPoint);
                        distance = distance - arrowH;
                        point = Util.getCirclePoint(point0, angle, distance);
                        lineContent.endPointX = (point.x);
                        lineContent.endPointY = (point.y);
                        break;
                    default:
                }
            }
            lineContent.rightAnglePoints = [];
        }
        

        line.width = Math.abs(lineContent.startPointX - lineContent.endPointX);
        line.height = Math.abs(lineContent.startPointY - lineContent.endPointY);

        this.setRightAnglePoint(line, target, to, targetDirection, toDirection);
        this.setConnectLineText(line, lineContent);
    }

    setConnectLineText(line, lineContent) {
        if (line == null || lineContent == null || line.isEmpty()) {
            return;
        }
        let textContent = line.lineContent.textContent;
        if (textContent == null) {
            textContent = new TextContent(new UiUtil().getString(strings.Mind_Usinghelp_Btn_3));
            if (Colors.isClear(this.mindBGColor)) {
                textContent.textColor = 0x333333;
            } else if (Colors.isDarkColor(this.mindBGColor)) {
                textContent.textColor = 0xffffff;
            } else {
                textContent.textColor = 0x333333;
            }
            textContent.textFontSize = 14;
            line.lineContent.textContent = textContent;
        }
        if (new Strings().isEmpty(textContent.text)) {
            textContent.text = new UiUtil().getString(strings.Mind_Usinghelp_Btn_3);
        }
        let size = MindElementCalculation.publicCaluleText(textContent.text, textContent.textFontSize, textContent.textBold);
        textContent.width = size.getWidth() + new UiUtil().dip2px(10);
        textContent.height = size.getHeight() + new UiUtil().dip2px(4);

        let startPoint = line.lineContent.startPoint();
        let endPoint = line.lineContent.endPoint();
        let x = line.width/2;
        let y = line.height/2;
        switch (lineContent.connectLineType) {
            case ConnectLineType.STRAIGHT_ARROW_LINE:
            case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                textContent.x = x - textContent.width/2;
                textContent.y = y - textContent.height/2;
                break;
            case ConnectLineType.CURVE_LINE:
                let startPointG = new CGPoint(startPoint.x + (line.x), startPoint.y + (line.y));
                let endPointG = new CGPoint(endPoint.x + (line.x), endPoint.y + (line.y));

                let precision = Math.floor(line.width - 1);
                var list = [];
                list.push(startPointG);
                list.push(line.lineContent.getStartControlPoint());
                list.push(line.lineContent.getEndControlPoint());
                list.push(endPointG);
                let points = new NodeConnectLineHit().bezierCalculate(list, precision);
                let pointsSize = points.length;
                if (points.isEmpty() || pointsSize < 2) {
                    textContent.x = x - textContent.width/2;
                    textContent.y = y - textContent.height/2;
                } else {
                    let centralPoint = points[Math.floor(pointsSize / 2)];
                    textContent.x = centralPoint.x - textContent.width/2 - line.x;
                    textContent.y = centralPoint.y - textContent.height/2 - line.y;
                }
                break;
            case ConnectLineType.RIGHT_ANGLE_LINE:
                let connectPoint = [];
                connectPoint.push(startPoint);
                let count = lineContent.rightAnglePoints.length;
                if (count > 0) {
                    for (let index = 0; index < count; index++) {
                        let cell = new Point((lineContent.rightAnglePoints[index].x),
                            (lineContent.rightAnglePoints[index].y))
                            connectPoint.push(cell);
                    }
                }
                connectPoint.push(endPoint);
                let connectPointSize = connectPoint.length;
                if (x > y) {
                    textContent.x = x - textContent.width/2;
                
                    if (connectPointSize > 1) {
                        for (let index = 0; index < connectPointSize; index++) {
                            if (index == connectPointSize-1) {
                                break;
                            }
                            let cell = connectPoint[index];
                            let nextCell = connectPoint[index + 1];
                            if (cell.x == x) {
                                textContent.y = cell.y - textContent.height/2;
                                break;
                            } else if (cell.x < x && nextCell.x > x) {
                                textContent.y = cell.y + (nextCell.y - cell.y) / 2 - textContent.height/2;
                                break;
                            } else if (cell.x > x && nextCell.x < x) {
                                textContent.y = cell.y + (nextCell.y - cell.y) / 2 - textContent.height/2;
                                break;
                            }
                        }
                    } else {
                        textContent.y = y - textContent.height/2;
                    }
                } else {
                    textContent.y = y - textContent.height/2;
                
                    if (connectPointSize > 1) {
                        for (let index = 0; index < connectPointSize; index++) {
                            if (index == connectPointSize-1) {
                                break;
                            }
                            let cell = connectPoint[index];
                            let nextCell = connectPoint[index + 1];
                            if (cell.y == y) {
                                textContent.x = cell.x - textContent.width/2;
                                break;
                            } else if (cell.y < y && nextCell.y > y) {
                                textContent.x = cell.x + (nextCell.x - cell.x) / 2 - textContent.width/2;
                                break;
                            } else if (cell.y > y && nextCell.y < y) {
                                textContent.x = cell.x + (nextCell.x - cell.x) / 2 - textContent.width/2;
                                break;
                            }
                        }
                    } else {
                        textContent.x = x - textContent.width/2;
                    }
                }
                
                break;
            default:
                textContent.x = x - textContent.width/2;
                textContent.y = y - textContent.height/2;
        }
    }

    getConnectLineRect(line) {
        if (line == null || line.isEmpty() || 
            line.type != MindElementType.NODE_CONNECT_LINE ||
             line.lineContent == null) {
            return new CGRect(0, 0, 0, 0);
        }
        let pointList = new Array()
        pointList.push(line.lineContent.startPoint())
        pointList.push(line.lineContent.endPoint())
        if (line.lineContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
            for (let index = 0; index < line.lineContent.rightAnglePoints.length; index++) {
                pointList.push(line.lineContent.rightAnglePoints[index])
            }
        }
        if (line.lineContent.connectLineType == ConnectLineType.CURVE_LINE) {
            let startPointG = new CGPoint(line.lineContent.startPointX + (line.x), line.lineContent.startPointY + (line.y));
            let endPointG = new CGPoint(line.lineContent.endPointX + (line.x), line.lineContent.endPointY + (line.y));

            let precision = Math.floor(line.width > line.heigth ? (line.width - 1) : (line.heigth - 1));
            var list = [];
            list.push(startPointG);
            list.push(line.lineContent.getStartControlPoint());
            list.push(line.lineContent.getEndControlPoint());
            list.push(endPointG);
            let points = new NodeConnectLineHit().bezierCalculate(list, precision);
            if (points.length > 1) {
                for (let index = 0; index < points.length; index++) {
                    pointList.push(new CGPoint(points[index].x - line.x, points[index].y - line.y))
                }
            }
        }
        let left = 99999999
        let right = -9999999
        let top = 99999999
        let bottom = -99999999
        for (let index = 0; index < pointList.length; index++) {
            let cell = pointList[index]
            if (cell.x + line.x < left) {
                left = cell.x + line.x
            }
            if (cell.x + line.x > right) {
                right = cell.x + line.x
            }
            if (cell.y + line.y < top) {
                top = cell.y + line.y
            }
            if (cell.y + line.y > bottom) {
                bottom = cell.y + line.y
            }
        }
        if (left < 0 || left >= 99999999 ||
            right < 0 || right >= 99999999 ||
            top < 0 || top >= 99999999 ||
            bottom < 0 || bottom >= 99999999) {
            return new CGRect(0, 0, 0, 0);
        }
        return new CGRect(left, top, right - left, bottom - top);
    }

    checkControlPoint(line, target, to) {
        if (line.lineContent == null || target.isEmpty() || to.isEmpty()) {
            return;
        }
        let targetCenter = target.getCenterPoint();
        let toCenter = to.getCenterPoint();

        let toLeftCenter = new Point(to.x, toCenter.y);
        let toRightCenter = new Point(to.x + to.width, toCenter.y);

        let startControlPoint = line.lineContent.getStartControlPoint();
        let toAndTargetCenter = new Point(targetCenter.x + (toCenter.x - targetCenter.x)/2, targetCenter.y + (toCenter.y - targetCenter.y)/2);
        let targetLeftCenter = new Point(target.x, targetCenter.y);
        let targetRightCenter = new Point(target.x + target.width, targetCenter.y);
        let endControlPoint = line.lineContent.getEndControlPoint();
        let maxSpace = new UiUtil().dip2px(1250);
        
        if (Util.getPointSpacing(startControlPoint, targetCenter) > maxSpace) {
            if (Util.getPointSpacing(startControlPoint, targetLeftCenter) > maxSpace) {
                if (Util.getPointSpacing(startControlPoint, targetRightCenter) > maxSpace) {
                    if (Util.getPointSpacing(startControlPoint, toAndTargetCenter) > maxSpace) {
                        line.lineContent.startControlPointX = targetCenter.x
                        line.lineContent.startControlPointY = toAndTargetCenter.y
                    }
                }
            }
        }
        
        if (Util.getPointSpacing(endControlPoint, toCenter) > maxSpace) {
            if (Util.getPointSpacing(endControlPoint, toLeftCenter) > maxSpace) {
                if (Util.getPointSpacing(endControlPoint, toRightCenter) > maxSpace) {
                    if (Util.getPointSpacing(endControlPoint, toAndTargetCenter) > maxSpace) {
                        line.lineContent.endControlPointX = toCenter.x
                        line.lineContent.endControlPointY = toAndTargetCenter.y
                    }
                }
            }
        }        
    }

    //RIGHT_ANGLE_LINE
    setRightAnglePoint(line, target, to, targetDirection = FlowChartDirection.RIGHT, toDirection = FlowChartDirection.LEFT) {
        if (line.lineContent == null) {
            return;
        }

        let lineContent = line.lineContent;
        // lineContent.rightAnglePoints = [];
        if (lineContent.connectLineType != ConnectLineType.RIGHT_ANGLE_LINE || 
            lineContent.rightAnglePoints.length > 0) {
            return;
        }
        
        let startPoint = lineContent.startPoint();
        let startPointGlobal = new Point(startPoint.x + (line.x), startPoint.y + (line.y));

        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(startPointGlobal, lineContent.getStartControlPoint());
            if (this.pointOnLeft(angle)) { //起点在目标点左侧
                this.startPointOnLeftTarget(line, target, to);
            } else if (this.pointOnRight(angle)) { //起点在目标点右侧
                this.startPointOnRightTarget(line, target, to);
            } else if (this.pointOnTop(angle)) { //起点在目标点上侧
                this.startPointOnTopTarget(line, target, to);
            } else {
                this.startPointOnBottomTarget(line, target, to);
            }
        } else {
            if (startPointGlobal.x < (target.x)) { //起点在目标点左侧
                this.startPointOnLeftTarget(line, target, to);
            } else if (startPointGlobal.x > (target.x + target.width)) { //起点在目标点右侧
                this.startPointOnRightTarget(line, target, to);
            } else if (this.checkDataTargetTop(line, target, to)) { //起点在目标点上侧
                this.startPointOnTopTarget(line, target, to);
            } else {
                this.startPointOnBottomTarget(line, target, to);
            }
        }
        let count = lineContent.rightAnglePoints.length;
        if (count > 0) {
            for (let index = 0; index < count; index++) {
                let item = lineContent.rightAnglePoints[index];
                lineContent.rightAnglePoints[index].x = item.x - line.x;
                lineContent.rightAnglePoints[index].y = item.y - line.y;
            }
        }
    }

    checkDataTargetTop(line, target, to) {
        if (line.lineContent == null) {
            return false;
        }

        let lineContent = line.lineContent;
        let startPoint = lineContent.startPoint();

        let startPointGlobal = new Point(startPoint.x + (line.x), startPoint.y + (line.y));
        let targetCenterPointGlobal = new Point((target.x + target.width / 2), (target.y + target.height / 2));

        if (target.mindElementShape == MindElementShapeType.Diamond ||
            target.mindElementShape == MindElementShapeType.Circular) {
            return startPointGlobal.y < targetCenterPointGlobal.y - new UiUtil().dip2px(4);
        } else {
            return startPointGlobal.y <= (target.y);
        }
    }

    checkDataToTop(line, target, to) {
        if (line.lineContent == null) {
            return false;
        }

        let lineContent = line.lineContent;
        let endPoint = lineContent.endPoint();

        let endPointGlobal = new Point(endPoint.x + (line.x), endPoint.y + (line.y));
        let toCenterPointGlobal = new Point((to.x + to.width / 2), (to.y + to.height / 2));

        if (to.mindElementShape == MindElementShapeType.Diamond ||
            to.mindElementShape == MindElementShapeType.Circular) {
            return endPointGlobal.y <= toCenterPointGlobal.y - new UiUtil().dip2px(4);
        } else {
            return endPointGlobal.y <= (to.y);
        }
    }

    checkDataTargetBottom(line, target, to) {
        if (line.lineContent == null) {
            return false;
        }

        let lineContent = line.lineContent;
        let startPoint = lineContent.startPoint();

        let startPointGlobal = new Point(startPoint.x + (line.x), startPoint.y + (line.y));
        let targetCenterPointGlobal = new Point((target.x + target.width / 2), (target.y + target.height / 2));

        if (target.mindElementShape == MindElementShapeType.Diamond ||
            target.mindElementShape == MindElementShapeType.Circular) {
            return startPointGlobal.y > targetCenterPointGlobal.y + new UiUtil().dip2px(4);
        } else {
            return startPointGlobal.y >= (target.y + target.height);
        }
    }

    checkDataToBottom(line, target, to) {
        if (line.lineContent == null) {
            return false;
        }

        let lineContent = line.lineContent;

        let endPoint = lineContent.endPoint();
        let endPointGlobal = new Point(endPoint.x + (line.x), endPoint.y + (line.y));
        let toCenterPointGlobal = new Point((to.x + to.width / 2), (to.y + to.height / 2));

        if (to.mindElementShape == MindElementShapeType.Diamond ||
            to.mindElementShape == MindElementShapeType.Circular) {
            return endPointGlobal.y >= toCenterPointGlobal.y + new UiUtil().dip2px(4);
        } else {
            return endPointGlobal.y >= (to.y + to.height);
        }
    }

    startPointOnBottomTarget(line, target, to) {
        if (line.lineContent == null) {
            return;
        }

        let lineContent = line.lineContent;
        lineContent.rightAnglePoints = [];

        if (lineContent.connectLineType != ConnectLineType.RIGHT_ANGLE_LINE) {
            return;
        }
        let minRightAngleSpace = new UiUtil().dip2px(15);

        let startPoint = lineContent.startPoint();
        let endPoint = lineContent.endPoint();
        let startPointGlobal = new Point(startPoint.x + (line.x), startPoint.y + (line.y));
        let endPointGlobal = new Point(endPoint.x + (line.x), endPoint.y + (line.y));

        let targetWidth = target.width;
        let targetHeight = target.height;
        let toWidth = to.width;
        let toHeight = to.height;

        let targetX = target.x;;
        let targetY = target.y;
        let toX = to.x;
        let toY = to.y;

        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            targetWidth = 10;
            targetHeight = 10;
            targetX = (startPointGlobal.x - 5);
            targetY = (startPointGlobal.y - 5);
        }
        if (to.type == MindElementType.NODE_CONNECT_LINE) {
            toWidth = 10;
            toHeight = 10;
            toX = (endPointGlobal.x - 5);
            toY = (endPointGlobal.y - 5);
        }

        let targetCenterPointGlobal = new Point((targetX + targetWidth / 2), (target.y + targetHeight / 2));
        let toCenterPointGlobal = new Point((toX + toWidth / 2), (toY + toHeight / 2));
        let startControlPoint = lineContent.getStartControlPoint();

        let startPointOnBottom = target.mindElementShape != MindElementShapeType.Diamond &&
                target.mindElementShape != MindElementShapeType.Circular &&
                target.mindElementShape != MindElementShapeType.Circular_Right_Top &&
                target.mindElementShape != MindElementShapeType.Ring &&
                target.mindElementShape != MindElementShapeType.Ring2 &&
                target.mindElementShape != MindElementShapeType.Ring2_UP &&
                target.mindElementShape != MindElementShapeType.Ring3 &&
                startPointGlobal.y > (targetY + targetHeight) ||
                (target.mindElementShape == MindElementShapeType.Diamond ||
                        target.mindElementShape == MindElementShapeType.Circular ||
                        target.mindElementShape == MindElementShapeType.Circular_Right_Top ||
                        target.mindElementShape == MindElementShapeType.Ring ||
                        target.mindElementShape == MindElementShapeType.Ring2 ||
                        target.mindElementShape == MindElementShapeType.Ring2_UP ||
                        target.mindElementShape == MindElementShapeType.Ring3) && startPointGlobal.y > (targetY + targetHeight / 2);
        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(startPointGlobal, lineContent.getStartControlPoint());
            startPointOnBottom = this.pointOnBottom(angle);
        }
        let endPointOnLeft = endPointGlobal.x <= (toX);
        let endPointOnRight = endPointGlobal.x >= (toX + toWidth);
        let endPointOnTop = this.checkDataToTop(line, target, to);
        if (to.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(endPointGlobal, lineContent.getEndControlPoint());
            endPointOnLeft = this.pointOnLeft(angle);
            endPointOnRight = this.pointOnRight(angle);
            endPointOnTop = this.pointOnTop(angle);
        }

        if (startPointOnBottom) { //起点在目标点下方
            if (endPointGlobal.y < startPointGlobal.y) { // 终点在起点的上方
                let dynamicWidth = startPointGlobal.y < startControlPoint.y ? (startControlPoint.y - startPointGlobal.y) / 2 : 0;
                let point1 = new Point(startPointGlobal.x, (startPointGlobal.y + minRightAngleSpace + dynamicWidth));
                lineContent.rightAnglePoints.push(new Point(point1.x, point1.y));
                if (endPointOnTop) { //终点在目标点的上方
                    if (startPointGlobal.x > endPointGlobal.x) { // 起点在终点右侧
                        if (targetX - (minRightAngleSpace) > toX + toWidth) {
                            lineContent.rightAnglePoints.push(new Point((targetX - minRightAngleSpace), (point1.y)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((toX - minRightAngleSpace), (point1.y)));
                        }
                    } else { // 起点在终点左侧
                        if (targetX + targetWidth + (minRightAngleSpace) < toX) {
                            lineContent.rightAnglePoints.push(new Point((targetX + targetWidth + (minRightAngleSpace)), (point1.y)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((toX + toWidth + (minRightAngleSpace)), (point1.y)));
                        }
                    }
                    lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x,
                        (endPointGlobal.y - minRightAngleSpace)));
                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                } else if (this.checkDataToBottom(line, target, to)) { //终点在目标点的下方
                    if (startPointGlobal.x > endPointGlobal.x) { // 起点在终点右侧
                        if (targetX - (minRightAngleSpace) < (endPointGlobal.x)) {
                            lineContent.rightAnglePoints.push(new Point(targetX - (minRightAngleSpace), (point1.y)));
                            lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y + minRightAngleSpace)));
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), (point1.y)));
                        }
                    } else {
                        if (targetX + targetWidth + (minRightAngleSpace) > (endPointGlobal.x)) {
                            lineContent.rightAnglePoints.push(new Point(targetX + targetWidth + (minRightAngleSpace), (point1.y)));
                            lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y + minRightAngleSpace)));
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), (point1.y)));
                        }
                    }
                } else if (endPointGlobal.x < (toX)) { //终点在目标点的左侧
                    if ((targetX + targetWidth) + minRightAngleSpace > (endPointGlobal.x) - minRightAngleSpace) {//终点在起点目标对象的左侧
                        if (targetX - (minRightAngleSpace) < (endPointGlobal.x - minRightAngleSpace)) {
                            lineContent.rightAnglePoints.push(new Point(targetX - (minRightAngleSpace), (point1.y)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x - minRightAngleSpace), (point1.y)));
                        }
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else {//终点在起点目标对象的右侧
                        lineContent.rightAnglePoints.push(new Point(targetX + targetWidth + (minRightAngleSpace), (point1.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    }
                } else { ////终点在目标点的右侧
                    if ((targetX) - minRightAngleSpace < (endPointGlobal.x) + minRightAngleSpace) {//终点在起点目标对象的右侧
                        if (targetX + targetWidth + (minRightAngleSpace) > (endPointGlobal.x + minRightAngleSpace)) {
                            lineContent.rightAnglePoints.push(new Point(targetX + targetWidth + (minRightAngleSpace), (point1.y)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x + minRightAngleSpace), (point1.y)));
                        }
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else {//终点在起点目标对象的左侧
                        lineContent.rightAnglePoints.push(new Point(targetX - (minRightAngleSpace), (point1.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    }
                }
            } else { // 终点在起点的下方
                let dynamicWidth = startPointGlobal.y < startControlPoint.y ? (startControlPoint.y - startPointGlobal.y) / 2 : 0;
                if (endPointOnRight) { //终点在目标点的右侧
                    if ((endPointGlobal.x + minRightAngleSpace) > startPointGlobal.x) {//终点在起点目标对象的右侧
                        let moveY = startPointGlobal.y + dynamicWidth + minRightAngleSpace > (toY) ?
                            (toY) - startPointGlobal.y : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y + moveY)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) + (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else { //终点在起点目标对象的左侧
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (endPointGlobal.y)));
                    }
                } else if (endPointGlobal.y > (toY + toHeight)) { //终点在目标点的下方
                    if ((toX) < startPointGlobal.x && (toX + toWidth) > startPointGlobal.x) {
                        let moveY = startPointGlobal.y + dynamicWidth + minRightAngleSpace > (toY) ?
                            (toY) - startPointGlobal.y : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y + moveY)));

                        if (endPointGlobal.x > startPointGlobal.x) {
                            lineContent.rightAnglePoints.push(new Point(toX - (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        } else {
                            lineContent.rightAnglePoints.push(new Point(toX + toWidth + (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        }
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y + minRightAngleSpace)));

                    } else {
                        let moveY = startPointGlobal.y + dynamicWidth + minRightAngleSpace < (endPointGlobal.y + minRightAngleSpace) ?
                            (endPointGlobal.y + minRightAngleSpace) - startPointGlobal.y : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y + moveY)));
                    }

                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));

                } else if (endPointOnLeft) { //终点在目标点的左侧
                    if ((endPointGlobal.x - minRightAngleSpace) < startPointGlobal.x) {//终点在起点目标对象的右侧
                        let moveY = startPointGlobal.y + dynamicWidth + minRightAngleSpace > (toY) ?
                            (toY) - startPointGlobal.y : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y + moveY)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) - (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else { //终点在起点目标对象的左侧
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (endPointGlobal.y)));
                    }
                } else { ////终点在目标点的上方
                    let moveY = startPointGlobal.y + dynamicWidth + minRightAngleSpace > endPointGlobal.y ?
                        endPointGlobal.y - startPointGlobal.y : dynamicWidth + minRightAngleSpace;
                    lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y + moveY)));
                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                }
            }
        }
    }

    startPointOnTopTarget(line, target, to) {
        if (line.lineContent == null) {
            return;
        }

        let lineContent = line.lineContent;
        lineContent.rightAnglePoints = [];

        if (lineContent.connectLineType != ConnectLineType.RIGHT_ANGLE_LINE) {
            return;
        }
        let minRightAngleSpace = new UiUtil().dip2px(15);

        let startPoint = lineContent.startPoint();
        let endPoint = lineContent.endPoint();
        let startPointGlobal = new Point(startPoint.x + (line.x), startPoint.y + (line.y));
        let endPointGlobal = new Point(endPoint.x + (line.x), endPoint.y + (line.y));

        let targetWidth = target.width;
        let targetHeight = target.height;
        let toWidth = to.width;
        let toHeight = to.height;

        let targetX = target.x;;
        let targetY = target.y;
        let toX = to.x;
        let toY = to.y;

        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            targetWidth = 10;
            targetHeight = 10;
            targetX = (startPointGlobal.x - 5);
            targetY = (startPointGlobal.y - 5);
        }
        if (to.type == MindElementType.NODE_CONNECT_LINE) {
            toWidth = 10;
            toHeight = 10;
            toX = (endPointGlobal.x - 5);
            toY = (endPointGlobal.y - 5);
        }

        let targetCenterPointGlobal = new Point((targetX + targetWidth / 2), (targetY + targetHeight / 2));
        let toCenterPointGlobal = new Point((toX + toWidth / 2), (toY + toHeight / 2));
        let startControlPoint = lineContent.getStartControlPoint();

        let startPointOnTop = this.checkDataTargetTop(line, target, to);
        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(startPointGlobal, lineContent.getStartControlPoint());
            startPointOnTop = this.pointOnTop(angle);
        }
        let endPointOnLeft = endPointGlobal.x <= (toX);
        let endPointOnRight = endPointGlobal.x >= (toX + toWidth);
        let endPointOnTop = this.checkDataToTop(line, target, to);
        if (to.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(endPointGlobal, lineContent.getEndControlPoint());
            endPointOnLeft = this.pointOnLeft(angle);
            endPointOnRight = this.pointOnRight(angle);
            endPointOnTop = this.pointOnTop(angle);
        }

        if (startPointOnTop) { //起点在目标点上方
            if (endPointGlobal.y > startPointGlobal.y) { // 终点在起点的下方
                let dynamicWidth = startPointGlobal.y > startControlPoint.y ? (startPointGlobal.y - startControlPoint.y) / 2 : 0;
                let point1 = new Point(startPointGlobal.x, startPointGlobal.y - minRightAngleSpace - dynamicWidth);
                lineContent.rightAnglePoints.push(new Point((point1.x), (point1.y)));
                if (this.checkDataToBottom(line, target, to)) { //终点在目标点的下方
                    if (startPointGlobal.x > endPointGlobal.x) { // 起点在终点右侧
                        if (targetX - (minRightAngleSpace) > toX + toWidth) {
                            lineContent.rightAnglePoints.push(new Point(targetX - (minRightAngleSpace), (point1.y)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point(toX - (minRightAngleSpace), (point1.y)));
                        }
                    } else { // 起点在终点左侧
                        if (targetX + targetWidth + (minRightAngleSpace) < toX) {
                            lineContent.rightAnglePoints.push(new Point(targetX + targetWidth + (minRightAngleSpace), (point1.y)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point(toX + toWidth + (minRightAngleSpace), (point1.y)));
                        }
                    }
                    lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x,
                        (endPointGlobal.y + minRightAngleSpace)));
                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                } else if (endPointOnTop) { //终点在目标点的上方
                    if (startPointGlobal.x > endPointGlobal.x) { // 起点在终点右侧
                        if (targetX - (minRightAngleSpace) < (endPointGlobal.x)) {
                            lineContent.rightAnglePoints.push(new Point(targetX - (minRightAngleSpace), (point1.y)));
                            lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y - minRightAngleSpace)));
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), (point1.y)));
                        }
                    } else {
                        if (targetX + targetWidth + (minRightAngleSpace) > (endPointGlobal.x)) {
                            lineContent.rightAnglePoints.push(new Point(targetX + targetWidth + (minRightAngleSpace), (point1.y)));
                            lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y - minRightAngleSpace)));
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), (point1.y)));
                        }
                    }
                } else if (endPointGlobal.x < (toX)) { //终点在目标点的左侧
                    if ((targetX + targetWidth) + minRightAngleSpace > (endPointGlobal.x) - minRightAngleSpace) {//终点在起点目标对象的左侧
                        if (targetX - (minRightAngleSpace) < (endPointGlobal.x - minRightAngleSpace)) {
                            lineContent.rightAnglePoints.push(new Point(targetX - (minRightAngleSpace), (point1.y)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x - minRightAngleSpace), (point1.y)));
                        }
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else {//终点在起点目标对象的右侧
                        lineContent.rightAnglePoints.push(new Point(targetX + targetWidth + (minRightAngleSpace), (point1.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    }
                } else { ////终点在目标点的右侧
                    if ((targetX) - minRightAngleSpace < (endPointGlobal.x) + minRightAngleSpace) {//终点在起点目标对象的右侧
                        if (targetX + targetWidth + (minRightAngleSpace) > (endPointGlobal.x + minRightAngleSpace)) {
                            lineContent.rightAnglePoints.push(new Point(targetX + targetWidth + (minRightAngleSpace), (point1.y)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x + minRightAngleSpace), (point1.y)));
                        }
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else {//终点在起点目标对象的左侧
                        lineContent.rightAnglePoints.push(new Point(targetX - (minRightAngleSpace), (point1.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    }
                }
            } else { // 终点在起点的上放---
                let dynamicWidth = startPointGlobal.y > startControlPoint.y ? (startPointGlobal.y - startControlPoint.y) / 2 : 0;
                if (endPointOnRight) { //终点在目标点的右侧
                    if ((endPointGlobal.x + minRightAngleSpace) > startPointGlobal.x) {//终点在起点目标对象的右侧
                        if (startPointGlobal.y - dynamicWidth - minRightAngleSpace > (toY) &&
                            startPointGlobal.y - dynamicWidth - minRightAngleSpace < (toY + toHeight)) {
                            if (startPointGlobal.x > (toX) &&
                                startPointGlobal.x < (toX + toWidth)) {
                                let moveY = startPointGlobal.y - dynamicWidth - minRightAngleSpace < (toY + toHeight) ?
                                    startPointGlobal.y - (toY + toHeight) : dynamicWidth + minRightAngleSpace;
                                lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y - moveY)));
                                lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) + (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                                lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                            } else {
                                let moveY = startPointGlobal.y - (toY) + minRightAngleSpace;
                                lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y - moveY)));
                                lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) + (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                                lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                            }
                        } else {
                            if (startPointGlobal.x > (toX) &&
                                startPointGlobal.x < (toX + toWidth)) {
                                let moveY = startPointGlobal.y - dynamicWidth - minRightAngleSpace < (toY + toHeight) ?
                                    startPointGlobal.y - (toY + toHeight) : dynamicWidth + minRightAngleSpace;
                                lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y - moveY)));
                                lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) + (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                                lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                            } else {
                                let moveY = startPointGlobal.y - dynamicWidth - minRightAngleSpace < (toY + toHeight) &&
                                    startPointGlobal.y - dynamicWidth - minRightAngleSpace > (toY) ?
                                    startPointGlobal.y - (toY) - minRightAngleSpace : dynamicWidth + minRightAngleSpace;
                                lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y - moveY)));
                                lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) + (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                                lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                            }
                        }
                    } else { //终点在起点目标对象的左侧
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (endPointGlobal.y)));
                    }
                } else if (endPointOnTop) { //终点在目标点的上方
                    if ((toX) < startPointGlobal.x && (toX + toWidth) > startPointGlobal.x) {
                        let moveY = startPointGlobal.y - dynamicWidth - minRightAngleSpace < (toY + toHeight) ?
                            startPointGlobal.y - (toY + toHeight) : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y - moveY)));

                        if (endPointGlobal.x > startPointGlobal.x) {
                            lineContent.rightAnglePoints.push(new Point(toX - (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        } else {
                            lineContent.rightAnglePoints.push(new Point(toX + toWidth + (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        }
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y - minRightAngleSpace)));

                    } else {
                        let moveY = startPointGlobal.y - dynamicWidth - minRightAngleSpace > (endPointGlobal.y - minRightAngleSpace) ?
                            startPointGlobal.y - (endPointGlobal.y - minRightAngleSpace) : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y - moveY)));
                    }

                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));

                } else if (endPointOnLeft) { //终点在目标点的左侧
                    if ((endPointGlobal.x - minRightAngleSpace) < startPointGlobal.x) {//终点在起点目标对象的右侧
                        let moveY = startPointGlobal.y - dynamicWidth - minRightAngleSpace < (toY + toHeight) ?
                            startPointGlobal.y - (toY + toHeight) : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y - moveY)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) - (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else { //终点在起点目标对象的左侧
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (endPointGlobal.y)));
                    }
                } else { ////终点在目标点的下方
                    let moveY = startPointGlobal.y - dynamicWidth - minRightAngleSpace < endPointGlobal.y ?
                        startPointGlobal.y - endPointGlobal.y : dynamicWidth + minRightAngleSpace;
                    lineContent.rightAnglePoints.push(new Point((startPointGlobal.x), (startPointGlobal.y - moveY)));
                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                }
            }
        }
    }

    startPointOnRightTarget(line, target, to) {
        if (line.lineContent == null) {
            return;
        }

        let lineContent = line.lineContent;
        lineContent.rightAnglePoints = [];

        if (lineContent.connectLineType != ConnectLineType.RIGHT_ANGLE_LINE) {
            return;
        }
        let minRightAngleSpace = new UiUtil().dip2px(15);

        let startPoint = lineContent.startPoint();
        let endPoint = lineContent.endPoint();
        let startPointGlobal = new Point(startPoint.x + (line.x), startPoint.y + (line.y));
        let endPointGlobal = new Point(endPoint.x + (line.x), endPoint.y + (line.y));

        let targetWidth = target.width;
        let targetHeight = target.height;
        let toWidth = to.width;
        let toHeight = to.height;

        let targetX = target.x;;
        let targetY = target.y;
        let toX = to.x;
        let toY = to.y;

        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            targetWidth = 10;
            targetHeight = 10;
            targetX = (startPointGlobal.x - 5);
            targetY = (startPointGlobal.y - 5);
        }
        if (to.type == MindElementType.NODE_CONNECT_LINE) {
            toWidth = 10;
            toHeight = 10;
            toX = (endPointGlobal.x - 5);
            toY = (endPointGlobal.y - 5);
        }

        let targetCenterPointGlobal = new Point((targetX + targetWidth / 2), (targetY + targetHeight / 2));
        let toCenterPointGlobal = new Point((toX + toWidth / 2), (toY + toHeight / 2));
        let startControlPoint = lineContent.getStartControlPoint();

        let startPointOnRight = startPointGlobal.x > (targetX + targetWidth);
        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(startPointGlobal, lineContent.getStartControlPoint());
            startPointOnRight = this.pointOnRight(angle);
        }
        let endPointOnLeft = endPointGlobal.x <= (toX);
        let endPointOnRight = endPointGlobal.x >= (toX + toWidth);
        let endPointOnTop = this.checkDataToTop(line, target, to);
        if (to.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(endPointGlobal, lineContent.getEndControlPoint());
            endPointOnLeft = this.pointOnLeft(angle);
            endPointOnRight = this.pointOnRight(angle);
            endPointOnTop = this.pointOnTop(angle);
        }

        if (startPointOnRight) { //起点在目标点左侧
            if (endPointGlobal.x < startPointGlobal.x) { // 终点在起点的左侧
                let dynamicWidth = startPointGlobal.x < startControlPoint.x ? (startControlPoint.x - startPointGlobal.x) / 2 : 0;
                let point1 = new Point(startPointGlobal.x + minRightAngleSpace + dynamicWidth, startPointGlobal.y);
                lineContent.rightAnglePoints.push(new Point((point1.x), (point1.y)));//------
                if (endPointOnRight) { //终点在目标点的右侧
                    if ((targetY) < endPointGlobal.y && (targetY + targetHeight) > endPointGlobal.y) {//终点在起点目标对象的正前方
                        if (startPointGlobal.y > endPointGlobal.y) { // 起点在终点下方
                            lineContent.rightAnglePoints.push(new Point((point1.x), targetY + targetHeight + (minRightAngleSpace)));
                        } else { // 起点在终点上方
                            lineContent.rightAnglePoints.push(new Point((point1.x), targetY - (minRightAngleSpace)));
                        }
                        if (endPointGlobal.x + minRightAngleSpace > (targetX)) {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x + ((targetX) - endPointGlobal.x) / 2), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x + minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        }
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));

                    } else { //终点在起点目标对象的后方上、下
                        lineContent.rightAnglePoints.push(new Point((point1.x), (endPointGlobal.y)));
                    }
                } else if (endPointOnTop) { //终点在目标点的上方
                    if (endPointGlobal.y - minRightAngleSpace > (targetY) &&
                        endPointGlobal.y - minRightAngleSpace < (targetY + targetHeight)) {
                        lineContent.rightAnglePoints.push(new Point((point1.x), targetY - (minRightAngleSpace)));
                    } else {
                        lineContent.rightAnglePoints.push(new Point((point1.x), (endPointGlobal.y - minRightAngleSpace)));
                    }
                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));

                } else if (endPointGlobal.x < (toX)) { //终点在目标点的左侧
                    if ((targetY) < (toY) - minRightAngleSpace &&
                        (targetY + targetHeight) > (toY) - minRightAngleSpace &&
                        (targetY) < (toY + toHeight) + minRightAngleSpace &&
                        (targetY + targetHeight) > (toY + toHeight) + minRightAngleSpace) {//终点在起点目标对象的正前方
                        if (startPointGlobal.y > endPointGlobal.y) { // 起点在终点下方
                            lineContent.rightAnglePoints.push(new Point((point1.x), targetY + targetHeight + (minRightAngleSpace)));
                        } else { // 起点在终点上方
                            lineContent.rightAnglePoints.push(new Point((point1.x), targetY - (minRightAngleSpace)));
                        }
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x - minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else if ((targetY) < (toY) - minRightAngleSpace &&
                        (targetY + targetHeight) > (toY) - minRightAngleSpace) {//终点在起点目标对象的正前方
                        lineContent.rightAnglePoints.push(new Point((point1.x), targetY + targetHeight + (minRightAngleSpace)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x - minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else if ((targetY) < (toY + toHeight) + minRightAngleSpace &&
                        (targetY + targetHeight) > (toY + toHeight) + minRightAngleSpace) {//终点在起点目标对象的正前方
                        lineContent.rightAnglePoints.push(new Point((point1.x), targetY - (minRightAngleSpace)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x - minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else { //终点在起点目标对象的后方上、下 // if (targetY) >= (toY + toHeight) + minRightAngleSpace
                        if (endPointGlobal.y >= toCenterPointGlobal.y) { //从下方拉线
                            lineContent.rightAnglePoints.push(new Point((point1.x), (toY + toHeight) + (minRightAngleSpace)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((point1.x), toY - (minRightAngleSpace)));
                        }
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x - minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    }
                } else { ////终点在目标点的下方
                    if (endPointGlobal.y + minRightAngleSpace > (targetY) &&
                        endPointGlobal.y + minRightAngleSpace < (targetY + targetHeight)) {
                        lineContent.rightAnglePoints.push(new Point((point1.x), targetY + targetHeight + (minRightAngleSpace)));
                    } else {
                        lineContent.rightAnglePoints.push(new Point((point1.x), (endPointGlobal.y + minRightAngleSpace)));
                    }
                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                }
            } else { // 终点在起点的右侧
                let dynamicWidth = startPointGlobal.x < startControlPoint.x ? (startControlPoint.x - startPointGlobal.x) / 2 : 0;
                if (endPointOnRight) { //终点在目标点的右侧
                    if ((startPointGlobal.y) < (toY + toHeight) &&
                        (startPointGlobal.y) > (toY)) {//终点在起点目标对象的正后方
                        let moveX = startPointGlobal.x + dynamicWidth + minRightAngleSpace > (toX) ?
                            (toX) - startPointGlobal.x : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x + moveX), (startPointGlobal.y)));

                        if (startPointGlobal.y > endPointGlobal.y) { // 起点在终点下方
                            lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (toY + toHeight) + (minRightAngleSpace)));
                        } else { // 起点在终点上方
                            lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (toY) - (minRightAngleSpace)));
                        }

                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) + (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else { //终点在起点目标对象的后方上、下
                        let moveX = startPointGlobal.x + dynamicWidth + minRightAngleSpace > endPointGlobal.x + minRightAngleSpace ?
                            dynamicWidth + minRightAngleSpace : endPointGlobal.x + minRightAngleSpace - startPointGlobal.x;

                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x + moveX), (startPointGlobal.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    }
                } else if (endPointOnTop) { //终点在目标点的上方
                    if (endPointGlobal.y > startPointGlobal.y) {
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), (startPointGlobal.y)));
                    } else {
                        let moveX = startPointGlobal.x + dynamicWidth + minRightAngleSpace > (toX) ?
                            (toX) - startPointGlobal.x : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x + moveX), (startPointGlobal.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y - minRightAngleSpace)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                    }
                } else if (endPointOnLeft) { //终点在目标点的左侧
                    let moveX = startPointGlobal.x + dynamicWidth + minRightAngleSpace > (toX) ?
                        (toX) - startPointGlobal.x : dynamicWidth + minRightAngleSpace;
                    lineContent.rightAnglePoints.push(new Point((startPointGlobal.x + moveX), (startPointGlobal.y)));
                    lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                } else { ////终点在目标点的下方
                    if (endPointGlobal.y < startPointGlobal.y) {
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), (startPointGlobal.y)));
                    } else {
                        let moveX = startPointGlobal.x + dynamicWidth + minRightAngleSpace > (toX) ?
                            (toX) - startPointGlobal.x : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x + moveX), (startPointGlobal.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y + minRightAngleSpace)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                    }
                }
            }
        }
    }

    startPointOnLeftTarget(line, target, to) {
        if (line.lineContent == null) {
            return;
        }

        let lineContent = line.lineContent;
        lineContent.rightAnglePoints = [];

        if (lineContent.connectLineType != ConnectLineType.RIGHT_ANGLE_LINE) {
            return;
        }
        let minRightAngleSpace = new UiUtil().dip2px(15);

        let startPoint = lineContent.startPoint();
        let endPoint = lineContent.endPoint();
        let startPointGlobal = new Point(startPoint.x + (line.x), startPoint.y + (line.y));
        let endPointGlobal = new Point(endPoint.x + (line.x), endPoint.y + (line.y));

        let targetWidth = target.width;
        let targetHeight = target.height;
        let toWidth = to.width;
        let toHeight = to.height;

        let targetX = target.x;;
        let targetY = target.y;
        let toX = to.x;
        let toY = to.y;

        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            targetWidth = 10;
            targetHeight = 10;
            targetX = (startPointGlobal.x - 5);
            targetY = (startPointGlobal.y - 5);
        }
        if (to.type == MindElementType.NODE_CONNECT_LINE) {
            toWidth = 10;
            toHeight = 10;
            toX = (endPointGlobal.x - 5);
            toY = (endPointGlobal.y - 5);
        }

        let targetCenterPointGlobal = new Point((targetX + targetWidth / 2), (targetY + targetHeight / 2));
        let toCenterPointGlobal = new Point((toX + toWidth / 2), (toY + toHeight / 2));
        let startControlPoint = lineContent.getStartControlPoint();

        let startPointOnLeft = startPointGlobal.x < (targetX);
        if (target.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(startPointGlobal, lineContent.getStartControlPoint());
            startPointOnLeft = this.pointOnLeft(angle);
        }
        let endPointOnLeft = endPointGlobal.x <= (toX);
        let endPointOnRight = endPointGlobal.x >= (toX + toWidth);
        let endPointOnTop = this.checkDataToTop(line, target, to);
        if (to.type == MindElementType.NODE_CONNECT_LINE) {
            let angle = Util.getCircleDegreesInPoint(endPointGlobal, lineContent.getEndControlPoint());
            endPointOnLeft = this.pointOnLeft(angle);
            endPointOnRight = this.pointOnRight(angle);
            endPointOnTop = this.pointOnTop(angle);
        }

        if (startPointOnLeft) { //起点在目标点左侧
            if (endPointGlobal.x >= startPointGlobal.x) { // 终点在起点的右侧
                let dynamicWidth = startPointGlobal.x > startControlPoint.x ? (startPointGlobal.x - startControlPoint.x) / 2 : 0;
                let point1 = new Point(startPointGlobal.x - minRightAngleSpace - dynamicWidth, startPointGlobal.y);
                lineContent.rightAnglePoints.push(new Point((point1.x), (point1.y)));
                if (endPointOnLeft) { //终点在目标点的左侧
                    if ((targetY) < endPointGlobal.y &&
                        (targetY + targetHeight) > endPointGlobal.y) {//终点在起点目标对象的正后方
                        if (startPointGlobal.y > endPointGlobal.y) { // 起点在终点下方
                            lineContent.rightAnglePoints.push(new Point((point1.x), targetY + targetHeight + (minRightAngleSpace)));
                        } else { // 起点在终点上方
                            lineContent.rightAnglePoints.push(new Point((point1.x), targetY - (minRightAngleSpace)));
                        }
                        if (endPointGlobal.x - minRightAngleSpace < (targetX + targetWidth)) {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x - (endPointGlobal.x - (targetX + targetWidth)) / 2), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((endPointGlobal.x - minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        }
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));

                    } else { //终点在起点目标对象的后方上、下
                        lineContent.rightAnglePoints.push(new Point((point1.x), (endPointGlobal.y)));
                    }
                } else if (endPointOnTop) { //终点在目标点的上方
                    if (endPointGlobal.y - minRightAngleSpace > (targetY) &&
                        endPointGlobal.y - minRightAngleSpace < (targetY + targetHeight)) {
                        lineContent.rightAnglePoints.push(new Point((point1.x), targetY - (minRightAngleSpace)));
                    } else {
                        lineContent.rightAnglePoints.push(new Point((point1.x), (endPointGlobal.y - minRightAngleSpace)));
                    }
                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));

                } else if (endPointOnRight) { //终点在目标点的右侧
                    if ((targetY) < (toY) - minRightAngleSpace &&
                        (targetY + targetHeight) > (toY + toHeight) + minRightAngleSpace) {//终点在起点目标对象的正后方
                        if (startPointGlobal.y > endPointGlobal.y) { // 起点在终点下方
                            lineContent.rightAnglePoints.push(new Point((point1.x), targetY + targetHeight + (minRightAngleSpace)));
                        } else { // 起点在终点上方
                            lineContent.rightAnglePoints.push(new Point((point1.x), targetY - (minRightAngleSpace)));
                        }
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x + minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else { //终点在起点目标对象的后方上、下 // if (targetY) >= (toY + toHeight) + minRightAngleSpace
                        if (endPointGlobal.y >= toCenterPointGlobal.y) { //从下方拉线
                            lineContent.rightAnglePoints.push(new Point((point1.x), (toY + toHeight) + (minRightAngleSpace)));
                        } else {
                            lineContent.rightAnglePoints.push(new Point((point1.x), toY - (minRightAngleSpace)));
                        }
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x + minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    }
                } else { ////终点在目标点的下方
                    if (endPointGlobal.y + minRightAngleSpace > (targetY) &&
                        endPointGlobal.y + minRightAngleSpace < (targetY + targetHeight)) {
                        lineContent.rightAnglePoints.push(new Point((point1.x), targetY + targetHeight + (minRightAngleSpace)));
                    } else {
                        lineContent.rightAnglePoints.push(new Point((point1.x), (endPointGlobal.y + minRightAngleSpace)));
                    }
                    lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                }
            } else { // 终点在起点的左侧
                let dynamicWidth = startPointGlobal.x > startControlPoint.x ? (startPointGlobal.x - startControlPoint.x) / 2 : 0;
                if (endPointOnLeft) { //终点在目标点的左侧
                    if ((startPointGlobal.y) < (toY + toHeight) &&
                        (startPointGlobal.y) > (toY)) {//终点在起点目标对象的正前方
                        let moveX = (toX + toWidth) + dynamicWidth + minRightAngleSpace > startPointGlobal.x ?
                            startPointGlobal.x - (toX + toWidth) : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x - moveX), (startPointGlobal.y)));

                        if (startPointGlobal.y > endPointGlobal.y) { // 起点在终点下方
                            lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (toY + toHeight) + (minRightAngleSpace)));
                        } else { // 起点在终点上方
                            lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (toY) - (minRightAngleSpace)));
                        }

                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x) - (minRightAngleSpace), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    } else { //终点在起点目标对象的后方上、下
                        let moveX = startPointGlobal.x - dynamicWidth - minRightAngleSpace < endPointGlobal.x - minRightAngleSpace ?
                            dynamicWidth + minRightAngleSpace : startPointGlobal.x - (endPointGlobal.x - minRightAngleSpace);
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x - moveX), (startPointGlobal.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                    }
                } else if (endPointOnTop) { //终点在目标点的上方
                    if (endPointGlobal.y > startPointGlobal.y) {
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), (startPointGlobal.y)));
                    } else {
                        let moveX = (toX + toWidth) + dynamicWidth + minRightAngleSpace > startPointGlobal.x ?
                            startPointGlobal.x - (toX + toWidth) : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x - moveX), (startPointGlobal.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y - minRightAngleSpace)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                    }
                } else if (endPointOnRight) { //终点在目标点的右侧
                    let moveX = (toX + toWidth) + dynamicWidth + minRightAngleSpace > startPointGlobal.x ?
                        startPointGlobal.x - (toX + toWidth) : dynamicWidth + minRightAngleSpace;
                    lineContent.rightAnglePoints.push(new Point((startPointGlobal.x - moveX), (startPointGlobal.y)));
                    lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y)));
                } else { ////终点在目标点的下方
                    if (endPointGlobal.y < startPointGlobal.y) {
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), (startPointGlobal.y)));
                    } else {
                        let moveX = (toX + toWidth) + dynamicWidth + minRightAngleSpace > startPointGlobal.x ?
                            startPointGlobal.x - (toX + toWidth) : dynamicWidth + minRightAngleSpace;
                        lineContent.rightAnglePoints.push(new Point((startPointGlobal.x - moveX), (startPointGlobal.y)));
                        lineContent.rightAnglePoints.push(new Point(lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].x, (endPointGlobal.y + minRightAngleSpace)));
                        lineContent.rightAnglePoints.push(new Point((endPointGlobal.x), lineContent.rightAnglePoints[lineContent.rightAnglePoints.length - 1].y));
                    }
                }
            }
        }
    }

    initConnectLineControlPoint(line, target, to, isCheckControlPoint = true) {
        if (target.isEmpty() || to.isEmpty()) {
            return;
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return;
        }
        switch (lineContent.connectLineType) {
            case ConnectLineType.STRAIGHT_ARROW_LINE:
            case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                this.straightArrowControlPoint(line, target, to);
                break;
            case ConnectLineType.CURVE_LINE:
                this.curveControlPoint(line, target, to);
                break;
            case ConnectLineType.RIGHT_ANGLE_LINE:
                this.rightAngleControlPoint(line, target, to);
                break;
            default:
                this.straightArrowControlPoint(line, target, to);
        }
        if (isCheckControlPoint) {
            this.checkControlPoint(line, target, to)
        }
    }

    straightArrowControlPoint(line, target, to) {
        if (target.isEmpty() || to.isEmpty()) {
            return;
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return;
        }
        let targetCenter = target.getCenterPoint();
        let toCenter = to.getCenterPoint();

        if (lineContent.startControlPointX <= 0 || lineContent.startControlPointY <= 0 ||
            lineContent.startControlPointX > targetCenter.x + 5000 ||
            lineContent.startControlPointY > targetCenter.y + 5000 ||
            lineContent.endControlPointX <= 0 || lineContent.endControlPointY <= 0 ||
            lineContent.endControlPointX > to.x + 5000 ||
            lineContent.endControlPointY > to.y + 5000) {
            let Xspaceing = (targetCenter.x - toCenter.x);
            let Yspaceing = (targetCenter.y - toCenter.y);
            let minSpaceX = new UiUtil().dip2px(90);
            let minSpaceY = new UiUtil().dip2px(90);

            let startControlPointX = 0;
            let startControlPointY = 0;
            let endControlPointX = 0;
            let endControlPointY = 0;
            let isCalculateStartControlPoint = false
            let isCalculateEndControlPoint = false

            if (target.mindElementShape == MindElementShapeType.Circular || 
                target.mindElementShape == MindElementShapeType.CircularAndRight ||
                target.mindElementShape == MindElementShapeType.Circular_Right_Top ||
                target.mindElementShape == MindElementShapeType.Ring ||
                target.mindElementShape == MindElementShapeType.Ring2 ||
                target.mindElementShape == MindElementShapeType.Ring3  ||
                target.mindElementShape == MindElementShapeType.Ring2_UP) {
                lineContent.startControlPointX = toCenter.x;
                lineContent.startControlPointY = toCenter.y;   
                isCalculateStartControlPoint = true
            }
            if (to.mindElementShape == MindElementShapeType.Circular || 
                to.mindElementShape == MindElementShapeType.CircularAndRight ||
                to.mindElementShape == MindElementShapeType.Circular_Right_Top ||
                to.mindElementShape == MindElementShapeType.Ring ||
                to.mindElementShape == MindElementShapeType.Ring2 ||
                to.mindElementShape == MindElementShapeType.Ring3  ||
                to.mindElementShape == MindElementShapeType.Ring2_UP) {
                lineContent.endControlPointX = targetCenter.x;
                lineContent.endControlPointY = targetCenter.y;   
                isCalculateEndControlPoint = true
            }
            if (Math.abs(Yspaceing) > Math.abs(Xspaceing)) { // 高大于宽
                if (Yspaceing < 0) { // 下方
                    startControlPointY = (targetCenter.y) - target.y + minSpaceY;
                    endControlPointY = -((toCenter.y) - to.y + minSpaceY);
                } else {
                    startControlPointY = -((targetCenter.y) - target.y + minSpaceY);
                    endControlPointY = (toCenter.y) - to.y + minSpaceY;
                }
                startControlPointX = 0;
                endControlPointX = 0;
            } else {
                if (Xspaceing < 0) { // 右侧
                    startControlPointX = (targetCenter.x) - target.x + minSpaceX;
                    endControlPointX = -((toCenter.x) - to.x + minSpaceX);
                } else {
                    startControlPointX = -((targetCenter.x) - target.x + minSpaceX);
                    endControlPointX = (toCenter.x) - to.x + minSpaceX;
                }
                startControlPointY = 0;
                endControlPointY = 0;
            }
            if (!isCalculateStartControlPoint) {
                lineContent.startControlPointX = startControlPointX + (targetCenter.x);
                lineContent.startControlPointY = startControlPointY + (targetCenter.y);
            }
            if (!isCalculateEndControlPoint) {
                lineContent.endControlPointX = endControlPointX + (toCenter.x);
                lineContent.endControlPointY = endControlPointY + (toCenter.y);
            }            

            if (to.type == MindElementType.NODE_CONNECT_LINE && to.lineContent != null &&
                to.lineContent.startControlPointX > 0 && to.lineContent.startControlPointY > 0 &&
                to.lineContent.endControlPointX > 0 && to.lineContent.endControlPointY > 0) {
                if (this.lastClickPoint.isJustSetPoint() && this.lastClickPoint.getId() == to.id &&
                    this.lastClickPoint.getPoint().x > 0 && this.lastClickPoint.getPoint().y > 0) {

                    let start = to.lineContent.startPoint();
                    let end = to.lineContent.endPoint();
                    let space = new UiUtil().dip2px(40);
                    let angle = Util.getCircleDegreesInPoint(start, end);
                    if (this.pointOnLeft(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y - space);
                    } else if (this.pointOnRight(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y + space);
                    } else if (this.pointOnTop(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x - space);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y);
                    } else {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x + space);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y);
                    }
                }
            }
        }
    }

    curveControlPoint(line, target, to) {
        if (target.isEmpty() || to.isEmpty()) {
            return;
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return;
        }
        let startPoint;
        let endPoint;

        let targetTopCenterX = target.x + target.width / 2;
        let targetTopCenterY = target.y;
        let targetBottomCenterX = targetTopCenterX;
        let targetBottomCenterY = targetTopCenterY + target.height;

        let targetLeftCenterX = target.x;
        let targetLeftCenterY = target.y + target.height / 2;
        let targetRightCenterX = target.x + target.width;
        let targetRightCenterY = targetLeftCenterY;

        let toTopCenterX = to.x + to.width / 2;
        let toTopCenterY = to.y;
        let toBottomCenterX = toTopCenterX;
        let toBottomCenterY = toTopCenterY + to.height;

        let toLeftCenterX = to.x;
        let toLeftCenterY = to.y + to.height / 2;
        let toRightCenterX = to.x + to.width;
        let toRightCenterY = toLeftCenterY;

        let curveStartPointX = 0;
        let curveStartPointY = 0;
        let curveEndPointX = 0;
        let curveEndPointY = 0;

        let targetCenter = target.getCenterPoint();
        let toCenter = to.getCenterPoint();

        if (lineContent.startControlPointX <= 0 || lineContent.startControlPointY <= 0 ||
            lineContent.startControlPointX > targetCenter.x + 5000 ||
            lineContent.startControlPointY > targetCenter.y + 5000 ||
            lineContent.endControlPointX <= 0 || lineContent.endControlPointY <= 0 ||
            lineContent.endControlPointX > to.x + 5000 ||
            lineContent.endControlPointY > to.y + 5000) {
            let Xspaceing = (targetCenter.x - toCenter.x);
            let Yspaceing = (targetCenter.y - toCenter.y);
            let marginX = new UiUtil().dip2px(8);
            let marginY = new UiUtil().dip2px(8);
            let minSpaceX = new UiUtil().dip2px(90);
            let minSpaceY = new UiUtil().dip2px(90);
            let horizontalVerticalSpace = new UiUtil().dip2px(40);

            let startControlPointX = 0;
            let startControlPointY = 0;
            let endControlPointX = 0;
            let endControlPointY = 0;

            if (Math.abs(Yspaceing) > minSpaceY && Math.abs(Xspaceing) > horizontalVerticalSpace) {
                if (Yspaceing < 0) { // 下方
                    startControlPointY = (toCenter.y - targetCenter.y) / 2;
                    endControlPointY = (targetCenter.y - toCenter.y) / 2;
                    startControlPointX = 0;
                    endControlPointX = 0;
                } else { // 上方
                    startControlPointY = (toCenter.y - targetCenter.y) / 2;
                    endControlPointY = (targetCenter.y - toCenter.y) / 2;
                    startControlPointX = 0;
                    endControlPointX = 0;
                }
            } else if (Math.abs(Xspaceing) > minSpaceX && Math.abs(Yspaceing) > horizontalVerticalSpace) {
                if (Xspaceing < 0) { // 右
                    startControlPointX = (toCenter.x - targetCenter.x) / 2;
                    endControlPointX = (targetCenter.x - toCenter.x) / 2;

                    startControlPointY = 0;
                    endControlPointY = 0;
                } else { // 左
                    startControlPointX = (toCenter.x - targetCenter.x) / 2;
                    endControlPointX = (targetCenter.x - toCenter.x) / 2;
                    startControlPointY = 0;
                    endControlPointY = 0;
                }
            } else if (Math.abs(Xspaceing) < Math.abs(Yspaceing)) { // 垂直方向
                if (Yspaceing < 0) { // 下方
                    startControlPointY = ((toCenter.y - targetCenter.y) * 0.6);
                    endControlPointY = ((targetCenter.y - toCenter.y) * 0.6);
                    startControlPointX = target.x - (targetCenter.x);
                    endControlPointX = to.x + to.width - (toCenter.x);
                } else { // 上方
                    startControlPointY = ((toCenter.y - targetCenter.y) * 0.6);
                    endControlPointY = ((targetCenter.y - toCenter.y) * 0.6);
                    startControlPointX = target.x - (targetCenter.x);
                    endControlPointX = to.x + to.width - (toCenter.x);
                }
            } else { //水平方向
                if (Xspaceing < 0) { // 右
                    startControlPointX = ((toCenter.x - targetCenter.x) * 0.6);
                    endControlPointX = ((targetCenter.x - toCenter.x) * 0.6);

                    startControlPointY = target.y - (targetCenter.y);
                    endControlPointY = to.y + to.height - (toCenter.y);
                } else { // 左
                    startControlPointX = ((toCenter.x - targetCenter.x) * 0.6);
                    endControlPointX = ((targetCenter.x - toCenter.x) * 0.6);
                    startControlPointY = target.y - (targetCenter.y);
                    endControlPointY = to.y + to.height - (toCenter.y);
                }
            }

            lineContent.startControlPointX = startControlPointX + (targetCenter.x);
            lineContent.startControlPointY = startControlPointY + (targetCenter.y);

            lineContent.endControlPointX = endControlPointX + (toCenter.x);
            lineContent.endControlPointY = endControlPointY + (toCenter.y);

            if (to.type == MindElementType.NODE_CONNECT_LINE && to.lineContent != null &&
                to.lineContent.startControlPointX > 0 && to.lineContent.startControlPointY > 0 &&
                to.lineContent.endControlPointX > 0 && to.lineContent.endControlPointY > 0) {
                if (this.lastClickPoint.isJustSetPoint() && this.lastClickPoint.getId() == to.id &&
                    this.lastClickPoint.getPoint().x > 0 && this.lastClickPoint.getPoint().y > 0) {

                    let start = to.lineContent.startPoint();
                    let end = to.lineContent.endPoint();
                    let space = new UiUtil().dip2px(40);
                    let angle = Util.getCircleDegreesInPoint(start, end);
                    if (this.pointOnLeft(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y - space);
                    } else if (this.pointOnRight(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y + space);
                    } else if (this.pointOnTop(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x - space);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y);
                    } else {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x + space);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y);
                    }
                }
            }
        }
    }

    rightAngleControlPoint(line, target, to) {
        if (target.isEmpty() || to.isEmpty()) {
            return;
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return;
        }

        let targetCenter = target.getCenterPoint();
        let toCenter = to.getCenterPoint();

        if (lineContent.startControlPointX <= 0 || lineContent.startControlPointY <= 0 ||
            lineContent.startControlPointX > targetCenter.x + 5000 ||
            lineContent.startControlPointY > targetCenter.y + 5000 ||
            lineContent.endControlPointX <= 0 || lineContent.endControlPointY <= 0 ||
            lineContent.endControlPointX > to.x + 5000 ||
            lineContent.endControlPointY > to.y + 5000) {
            let Xspaceing = (targetCenter.x - toCenter.x);
            let Yspaceing = (targetCenter.y - toCenter.y);
            let marginX = new UiUtil().dip2px(8);
            let marginY = new UiUtil().dip2px(8);
            let minSpaceX = new UiUtil().dip2px(90);
            let minSpaceY = new UiUtil().dip2px(90);
            let horizontalVerticalSpace = new UiUtil().dip2px(40);

            let startControlPointX = 0;
            let startControlPointY = 0;
            let endControlPointX = 0;
            let endControlPointY = 0;

            if (target.y > to.y && target.y < to.y + to.height ||
                target.y + target.height > to.y && target.y + target.height < to.y + to.height ||
                to.y > target.y && to.y < target.y + target.height ||
                to.y + to.height > target.y && to.y + to.height < target.y + target.height ||
                (targetCenter.y) > to.y && (targetCenter.y) < to.y + to.height ||
                (toCenter.y) > target.y && (toCenter.y) < target.y + target.height) { //水平
                startControlPointY = -(target.height / 2 + minSpaceY);
                endControlPointY = -(to.height / 2 + minSpaceY);
                startControlPointX = 0;
                endControlPointX = 0;
            } else if (target.x > to.x && target.x < to.x + to.width ||
                target.x + target.width > to.x && target.x + target.width < to.x + to.width ||
                to.x > target.x && to.x < target.x + target.width ||
                to.x + to.width > target.x && to.x + to.width < target.x + target.width ||
                (targetCenter.x) > to.x && (targetCenter.x) < to.x + to.width ||
                (toCenter.x) > target.x && (toCenter.x) < target.x + target.width) { //垂直
                startControlPointY = 0;
                endControlPointY = 0;
                startControlPointX = (target.width / 2 + minSpaceX);
                endControlPointX = (to.width / 2 + minSpaceX);
            } else if (Math.abs(Yspaceing) > minSpaceY && Math.abs(Xspaceing) > horizontalVerticalSpace) {
                if (Yspaceing < 0) { // 下方
                    startControlPointY = (toCenter.y - targetCenter.y) / 2;
                    endControlPointY = (targetCenter.y - toCenter.y) / 2;
                    startControlPointX = 0;
                    endControlPointX = 0;
                } else { // 上方
                    startControlPointY = (toCenter.y - targetCenter.y) / 2;
                    endControlPointY = (targetCenter.y - toCenter.y) / 2;
                    startControlPointX = 0;
                    endControlPointX = 0;
                }
            } else if (Math.abs(Xspaceing) > minSpaceX && Math.abs(Yspaceing) > horizontalVerticalSpace) {
                if (Xspaceing < 0) { // 右
                    startControlPointX = (toCenter.x - targetCenter.x) / 2;
                    endControlPointX = (targetCenter.x - toCenter.x) / 2;

                    startControlPointY = 0;
                    endControlPointY = 0;
                } else { // 左
                    startControlPointX = (toCenter.x - targetCenter.x) / 2;
                    endControlPointX = (targetCenter.x - toCenter.x) / 2;
                    startControlPointY = 0;
                    endControlPointY = 0;
                }
            } else if (Math.abs(Xspaceing) < Math.abs(Yspaceing)) { // 垂直方向
                if (Yspaceing < 0) { // 下方
                    startControlPointY = ((toCenter.y - targetCenter.y) * 0.6);
                    endControlPointY = ((targetCenter.y - toCenter.y) * 0.6);
                    startControlPointX = target.x - (targetCenter.x);
                    endControlPointX = to.x + to.width - (toCenter.x);
                } else { // 上方
                    startControlPointY = ((toCenter.y - targetCenter.y) * 0.6);
                    endControlPointY = ((targetCenter.y - toCenter.y) * 0.6);
                    startControlPointX = target.x - (targetCenter.x);
                    endControlPointX = to.x + to.width - (toCenter.x);
                }
            } else { //水平方向
                if (Xspaceing < 0) { // 右
                    startControlPointX = ((toCenter.x - targetCenter.x) * 0.6);
                    endControlPointX = ((targetCenter.x - toCenter.x) * 0.6);

                    startControlPointY = target.y - (targetCenter.y);
                    endControlPointY = to.y + to.height - (toCenter.y);
                } else { // 左
                    startControlPointX = ((toCenter.x - targetCenter.x) * 0.6);
                    endControlPointX = ((targetCenter.x - toCenter.x) * 0.6);
                    startControlPointY = target.y - (targetCenter.y);
                    endControlPointY = to.y + to.height - (toCenter.y);
                }
            }

            lineContent.startControlPointX = startControlPointX + (targetCenter.x);
            lineContent.startControlPointY = startControlPointY + (targetCenter.y);

            lineContent.endControlPointX = endControlPointX + (toCenter.x);
            lineContent.endControlPointY = endControlPointY + (toCenter.y);

            if (to.type == MindElementType.NODE_CONNECT_LINE && to.lineContent != null &&
                to.lineContent.startControlPointX > 0 && to.lineContent.startControlPointY > 0 &&
                to.lineContent.endControlPointX > 0 && to.lineContent.endControlPointY > 0) {
                if (this.lastClickPoint.isJustSetPoint() && this.lastClickPoint.getId() == to.id &&
                    this.lastClickPoint.getPoint().x > 0 && this.lastClickPoint.getPoint().y > 0) {

                    let start = to.lineContent.startPoint();
                    let end = to.lineContent.endPoint();
                    let space = new UiUtil().dip2px(40);
                    let angle = Util.getCircleDegreesInPoint(start, end);
                    if (this.pointOnLeft(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y - space);
                    } else if (this.pointOnRight(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y + space);
                    } else if (this.pointOnTop(angle)) {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x - space);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y);
                    } else {
                        lineContent.endControlPointX = (this.lastClickPoint.getPoint().x + space);
                        lineContent.endControlPointY = (this.lastClickPoint.getPoint().y);
                    }
                }
            }
        }
    }

    getNodeById(id) {
        let node = this.mainMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }

        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }

        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (node.isEmpty() && this.nodeConnectLineDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }

    findHitData(point, margin) {
        let data = this.findHitDataInUnit(this.mainMindNodeUnit, point, margin, false)
        if (!data.isEmpty()) {
            return data;
        }
        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            data = this.findHitDataInUnit(unit, point, margin, false)
            if (!data.isEmpty()) {
                return data;
            }
        }
        
        let keys = this.nodeConnectLineDataDict.keys();
        let length = keys.length
        for (let index = 0; index < length; index++) {
            let line = this.nodeConnectLineDataDict.get(keys[index]);
            let hitPoint = line.hitPoint(point, margin);
            if (hitPoint.x > -1 && hitPoint.y > -1) {
                return line;
            }
        }

        data = this.findEncircleDatas(this.mainMindNodeUnit, point, margin)
        if (!data.isEmpty()) {
            return data;
        }
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            data = this.findEncircleDatas(unit, point, margin)
            if (!data.isEmpty()) {
                return data;
            }
        }
        return new MindElementData()
    }

    findEncircleDatas(unit, point, margin) {
        if (unit != null && unit.rootTreeNode != null && unit.encircleMindElementDataDict != null) {
            let encircleKeys = unit.encircleMindElementDataDict.keys()
            let encircleKeysLength = encircleKeys.length
            for (let index = 0; index < encircleKeysLength; index++) {
                let key = encircleKeys[index]
                let mind = unit.encircleMindElementDataDict.get(key);
                let hitPoint = mind.hitPoint(point, margin);
                if (hitPoint.x > -1 && hitPoint.y > -1) {
                    return mind;
                }
            }
        }
        return new MindElementData()
    }

    findHitDataInUnit(unit, point, margin, isIncludeEncircle = true) {
        if (unit != null && unit.rootTreeNode != null) {
            let keys = unit.mainMindElementDataDict.keys();
            let length = keys.length
            for (let index = 0; index < length; index++) {
                let node = unit.mainMindElementDataDict.get(keys[index]);
                let hitPoint = node.value.hitPoint(point, margin);
                if (hitPoint.x > -1 && hitPoint.y > -1) {
                    return node.value;
                }
            }
            let generalizationKeys = unit.generalizationMindElementDataDict.keys()
            let generalizationKeysLength = generalizationKeys.length
            for (let index = 0; index < generalizationKeysLength; index++) {
                let key = generalizationKeys[index]
                let node = unit.generalizationMindElementDataDict.get(key);
                let hitPoint = node.value.hitPoint(point, margin);
                if (hitPoint.x > -1 && hitPoint.y > -1) {
                    return node.value;
                }
            }
            if (isIncludeEncircle) {
                let encircleKeys = unit.encircleMindElementDataDict.keys()
                let encircleKeysLength = encircleKeys.length
                for (let index = 0; index < encircleKeysLength; index++) {
                    let key = encircleKeys[index]
                    let mind = unit.encircleMindElementDataDict.get(key);
                    let hitPoint = mind.hitPoint(point, margin);
                    if (hitPoint.x > -1 && hitPoint.y > -1) {
                        return mind;
                    }
                }
            }

            let explainMindElementDataDictKeys = unit.explainMindElementDataDict.keys();
            let explainMindElementDataDictKeysLength = explainMindElementDataDictKeys.length;
            for (let index = 0; index < explainMindElementDataDictKeysLength; index++) {
                let key = explainMindElementDataDictKeys[index];
                let data = unit.explainMindElementDataDict.get(key);
                let hitPoint = data.hitPoint(point, margin);
                if (hitPoint.x > -1 && hitPoint.y > -1) {
                    return data;
                }
            }
        }
        return new MindElementData()
    }

    getInitLineTargetI(line, target, to) {
        if (line == null || line.isEmpty() || 
            target == null || target.isEmpty() ||
            to == null || to.isEmpty()) {
            return new Point(-1, -1)
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return new Point(-1, -1);
        }
        let targetCenterPoint = target.getCenterPoint()
        let toCenterPoint = to.getCenterPoint()
        let margin = lineContent.getLineStartMargin();
        let targetI = new Point(-1, -1)

        let targetAndToLineList = this.getDataConnectLine(target, to);         
        if (targetAndToLineList.length > 1) {
            let isHasInit = false;
            let isTopHit = false;
            let isLeftHit = false;
            let isRightHit = false;
            let isBottomHit = false;
            for (let index = 0; index < targetAndToLineList.length; index++) {
                const cell = targetAndToLineList[index];
                if (cell.x > 0 && cell.y > 0) {
                    isHasInit = true
                    let startPoint = cell.lineContent.startPoint();
                    let endPoint = cell.lineContent.endPoint();
                    let startPointGlobal = new Point(startPoint.x + (cell.x), startPoint.y + (cell.y));
                    let endPointGlobal = new Point(endPoint.x + (cell.x), endPoint.y + (cell.y));
                    if (cell.parentNodeId == target.id) {
                        isTopHit = isTopHit ? isTopHit : startPointGlobal.y < target.y;
                        isBottomHit = isBottomHit ? isBottomHit : startPointGlobal.y > target.y + target.height;
                        isLeftHit = isLeftHit ? isLeftHit : startPointGlobal.x < target.x;
                        isRightHit = isRightHit ? isRightHit : startPointGlobal.x > target.x + target.width;
                    } else {
                        isTopHit = isTopHit ? isTopHit : endPointGlobal.y < target.y;
                        isBottomHit = isBottomHit ? isBottomHit : endPointGlobal.y > target.y + target.height;
                        isLeftHit = isLeftHit ? isLeftHit : endPointGlobal.x < target.x;
                        isRightHit = isRightHit ? isRightHit : endPointGlobal.x > target.x + target.width;
                    }
                }
            }
            let targetAtToLeft = target.x + target.width < to.x;
            let targetAtToRigth = target.x > to.x + to.width;
            let targetAtToTop = target.y + target.height < to.y;
            let targetAtToBottom = target.y > to.y + to.height;
            let targetAtToHorizontalCenter = toCenterPoint.x > target.x && toCenterPoint.x < target.x + target.width // to在target横向中间
            let targetAtToVerticalCenter = toCenterPoint.y > target.y && toCenterPoint.y < target.y + target.height // to在target竖向中间
            if (isHasInit) {
                if (!targetAtToBottom && targetAtToTop) { // to在target下
                    if (targetAtToHorizontalCenter) { //正下方
                        if (!isBottomHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                        } else if (!isLeftHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        } else if (!isRightHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        } else if (!isTopHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y - 100), margin);
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                        }
                    } else if (targetAtToRigth) {
                        if (isLeftHit) { //已经存在线条
                            if (!isBottomHit) {
                                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                            } else {
                                targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                            }
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        }
                    } else if (targetAtToLeft) {
                        if (isRightHit) { //已经存在线条
                            if (!isBottomHit) {
                                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                            } else {
                                targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                            }
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        }
                    } else {
                        targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, toCenterPoint.y), margin);
                    }
                } else if (!targetAtToTop && targetAtToBottom) { // to在target上
                    if (targetAtToHorizontalCenter) { //正上方
                        if (!isTopHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y - 100), margin);
                        } else if (!isLeftHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        } else if (!isRightHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        } else if (!isBottomHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y - 100), margin);
                        }
                    } else if (targetAtToRigth) {
                        if (isLeftHit) { //已经存在线条
                            if (!isTopHit) {
                                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y - 100), margin);
                            } else {
                                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, toCenterPoint.y), margin);
                            }
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        }
                    } else if (targetAtToLeft) {
                        if (isRightHit) { //已经存在线条
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, toCenterPoint.y), margin);
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        }
                    } else {
                        targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, toCenterPoint.y), margin);
                    }
                } else if (!targetAtToLeft && targetAtToRigth) { // to在target左
                    if (targetAtToVerticalCenter) { //正左方
                        if (!isLeftHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        } else if (!isTopHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y - 100), margin);
                        } else if (!isBottomHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                        } else if (!isRightHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        }
                    } else if (targetAtToBottom) {
                        if (isLeftHit) { //已经存在线条
                            if (!isTopHit) {
                                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y - 100), margin);
                            } else {
                                targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                            }
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        }
                    } else if (targetAtToTop) {
                        if (isLeftHit) { //已经存在线条
                            if (!isBottomHit) {
                                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                            } else {
                                targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                            }
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        }
                    } else {
                        targetI = target.getRightAngleShapeHitPoint(toCenterPoint, margin); 
                    }
                } else if (targetAtToLeft && !targetAtToRigth) { // to在target右
                    if (targetAtToVerticalCenter) { //正右方
                        if (!isRightHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        } else if (!isTopHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y - 100), margin);
                        } else if (!isBottomHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                        } else if (!isLeftHit) {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x - 100, targetCenterPoint.y), margin);
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        }
                    } else if (targetAtToBottom) {
                        if (isRightHit) { //已经存在线条
                            if (!isTopHit) {
                                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y - 100), margin);
                            } else {
                                targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                            }
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        }
                    } else if (targetAtToTop) {
                        if (isRightHit) { //已经存在线条
                            if (!isBottomHit) {
                                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, target.y + target.height + 100), margin);
                            } else {
                                targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                            }
                        } else {
                            targetI = target.getRightAngleShapeHitPoint(new Point(target.x + target.width + 100, targetCenterPoint.y), margin);
                        }
                    } else {
                        targetI = target.getRightAngleShapeHitPoint(toCenterPoint, margin); 
                    }
                } else {
                    targetI = target.getRightAngleShapeHitPoint(toCenterPoint, margin);      
                }
            } else {
                if (target.x > to.x + to.width + 10 || target.x + target.width < to.x + 10) { //左侧 右侧
                    targetI = target.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, targetCenterPoint.y), margin);
                } else if (target.y > to.y + to.height + 10 || target.y + target.height < to.y + 10) { // 上下
                    targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, toCenterPoint.y), margin);
                } else {
                    targetI = target.getRightAngleShapeHitPoint(toCenterPoint, margin);      
                } 
            }
        } else {
            if (target.x > to.x + to.width + 10 || target.x + target.width < to.x + 10) { //左侧 右侧
                targetI = target.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, targetCenterPoint.y), margin);
            } else if (target.y > to.y + to.height + 10 || target.y + target.height < to.y + 10) { // 上下
                targetI = target.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, toCenterPoint.y), margin);
            } else {
                targetI = target.getRightAngleShapeHitPoint(toCenterPoint, margin);      
            }
        }
        return targetI;
    }

    getInitLineToI(line, target, to) {
        if (line == null || line.isEmpty() || 
            target == null || target.isEmpty() ||
            to == null || to.isEmpty()) {
            return new Point(-1, -1)
        }
        let lineContent = line.lineContent;
        if (lineContent == null) {
            return new Point(-1, -1);
        }
        let targetCenterPoint = target.getCenterPoint()
        let toCenterPoint = to.getCenterPoint()
        let margin = lineContent.getLineEndMargin();
        let toI = new Point(-1, -1)

        let targetAndToLineList = this.getDataConnectLine(target, to);         
        if (targetAndToLineList.length > 1) {
            let isHasInit = false;
            let isTopHit = false;
            let isLeftHit = false;
            let isRightHit = false;
            let isBottomHit = false;
            for (let index = 0; index < targetAndToLineList.length; index++) {
                const cell = targetAndToLineList[index];
                if (cell.x > 0 && cell.y > 0) {
                    isHasInit = true
                    let startPoint = cell.lineContent.startPoint();
                    let endPoint = cell.lineContent.endPoint();
                    let startPointGlobal = new Point(startPoint.x + (cell.x), startPoint.y + (cell.y));
                    let endPointGlobal = new Point(endPoint.x + (cell.x), endPoint.y + (cell.y));
                    if (cell.parentNodeId == to.id) {
                        isTopHit = isTopHit ? isTopHit : startPointGlobal.y < to.y;
                        isBottomHit = isBottomHit ? isBottomHit : startPointGlobal.y > to.y + to.height;
                        isLeftHit = isLeftHit ? isLeftHit : startPointGlobal.x < to.x;
                        isRightHit = isRightHit ? isRightHit : startPointGlobal.x > to.x + to.width;
                    } else {
                        isTopHit = isTopHit ? isTopHit : endPointGlobal.y < to.y;
                        isBottomHit = isBottomHit ? isBottomHit : endPointGlobal.y > to.y + to.height;
                        isLeftHit = isLeftHit ? isLeftHit : endPointGlobal.x < to.x;
                        isRightHit = isRightHit ? isRightHit : endPointGlobal.x > to.x + to.width;
                    }
                }
            }
            let toAtTargetLeft = to.x + to.width < target.x;
            let toAtTargetRigth = to.x > target.x + target.width;
            let toAtTargetTop = to.y + target.height < target.y;
            let toAtTargetBottom = to.y > target.y + target.height;
            let toAtTargetHorizontalCenter = targetCenterPoint.x > to.x && targetCenterPoint.x < to.x + to.width // target在to横向中间
            let toAtTargetVerticalCenter = targetCenterPoint.y > to.y && targetCenterPoint.y < to.y + to.height // target在to竖向中间
            if (isHasInit) {
                if (!toAtTargetBottom && toAtTargetTop) { // target在to下
                    if (toAtTargetHorizontalCenter) { //正下方
                        if (!isBottomHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                        } else if (!isLeftHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        } else if (!isRightHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        } else if (!isTopHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                        }
                    } else if (toAtTargetRigth) {
                        if (isLeftHit) { //已经存在线条
                            if (!isBottomHit) {
                                toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                            } else {
                                toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                            }
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        }
                    } else if (toAtTargetLeft) {
                        if (isRightHit) { //已经存在线条
                            if (!isBottomHit) {
                                toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                            } else {
                                toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                            }
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        }
                    } else {
                        toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                    }
                } else if (!toAtTargetTop && toAtTargetBottom) { // to在target上
                    if (toAtTargetHorizontalCenter) { //正上方
                        if (!isTopHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                        } else if (!isLeftHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        } else if (!isRightHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        } else if (!isBottomHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                        }
                    } else if (toAtTargetRigth) {
                        if (isLeftHit) { //已经存在线条
                            if (!isTopHit) {
                                toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                            } else {
                                toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                            }
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        }
                    } else if (toAtTargetLeft) {
                        if (isRightHit) { //已经存在线条
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        }
                    } else {
                        toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                    }
                } else if (!toAtTargetLeft && toAtTargetRigth) { // to在target左
                    if (toAtTargetVerticalCenter) { //正左方
                        if (!isLeftHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        } else if (!isTopHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                        } else if (!isBottomHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                        } else if (!isRightHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        }
                    } else if (toAtTargetBottom) {
                        if (isLeftHit) { //已经存在线条
                            if (!isTopHit) {
                                toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                            } else {
                                toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                            }
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        }
                    } else if (toAtTargetTop) {
                        if (isLeftHit) { //已经存在线条
                            if (!isBottomHit) {
                                toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                            } else {
                                toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                            }
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        }
                    } else {
                        toI = to.getRightAngleShapeHitPoint(toCenterPoint, margin); 
                    }
                } else if (toAtTargetLeft && !toAtTargetRigth) { // to在target右
                    if (toAtTargetVerticalCenter) { //正右方
                        if (!isRightHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        } else if (!isTopHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                        } else if (!isBottomHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                        } else if (!isLeftHit) {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x - 100, toCenterPoint.y), margin);
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        }
                    } else if (toAtTargetBottom) {
                        if (isRightHit) { //已经存在线条
                            if (!isTopHit) {
                                toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y - 100), margin);
                            } else {
                                toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                            }
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        }
                    } else if (toAtTargetTop) {
                        if (isRightHit) { //已经存在线条
                            if (!isBottomHit) {
                                toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, to.y + to.height + 100), margin);
                            } else {
                                toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                            }
                        } else {
                            toI = to.getRightAngleShapeHitPoint(new Point(to.x + to.width + 100, toCenterPoint.y), margin);
                        }
                    } else {
                        toI = to.getRightAngleShapeHitPoint(toCenterPoint, margin); 
                    }
                } else {
                    toI = to.getRightAngleShapeHitPoint(toCenterPoint, margin);      
                }
            } else {
                if (target.x > to.x + to.width + 10 || target.x + target.width < to.x + 10) { //左侧 右侧
                    toI = to.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, toCenterPoint.y), margin);
                } else if (target.y > to.y + to.height + 10 || target.y + target.height < to.y + 10) { // 上下
                    toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, targetCenterPoint.y), margin);
                } else {
                    toI = to.getRightAngleShapeHitPoint(targetCenterPoint, margin);
                }
            }
        } else {
            if (target.x > to.x + to.width + 10 || target.x + target.width < to.x + 10) { //左侧 右侧
                toI = to.getRightAngleShapeHitPoint(new Point(targetCenterPoint.x, toCenterPoint.y), margin);
            } else if (target.y > to.y + to.height + 10 || target.y + target.height < to.y + 10) { // 上下
                toI = to.getRightAngleShapeHitPoint(new Point(toCenterPoint.x, targetCenterPoint.y), margin);
            } else {
                toI = to.getRightAngleShapeHitPoint(targetCenterPoint, margin);
            }
        }
        return toI;
    }

    pointOnLeft(angle) {
        return angle >= 135 && angle <= 225;
    }

    pointOnRight(angle) {
        return (angle >= 315 && angle <= 360) || angle >= 0 && angle <= 45;
    }

    pointOnTop(angle) {
        return angle >= 225 && angle <= 315;
    }

    pointOnBottom(angle) {
        return angle >= 45 && angle <= 135;
    }

    pointOnRectLeft(point, rect) {

        return point.x < rect.x;
    }

    pointOnRectRight(point, rect) {
        return point.x > rect.x + rect.width();
    }

    pointOnRectTop(point, rect) {
        return point.y < rect.y;
    }

    pointOnRectBottom(point, rect) {
        return point.y > rect.y + rect.height();
    }

    isIntersecteData(data1, data2) {
        if (data1 == null || data1.isEmpty() ||
            data2 == null || data2.isEmpty()) {
            return false
        }
        let rect1 = data1.getRect();
        let rect2 = data2.getRect();
        return Util.isIntersectedForRect(rect1, rect2)
    }

    isFreeLine(line) {
        if (line == null || line.isEmpty() || line.lineContent == null || line.type != MindElementType.NODE_CONNECT_LINE) {
            return false
        }
        if (line.parentNodeId == line.lineContent.targetId && line.parentNodeId == line.id) {
            return true
        }
        let targetNode = this.getNodeById(line.parentNodeId)
        let toNode = this.getNodeById(line.lineContent.targetId)
        if (targetNode.isEmpty() || toNode.isEmpty()) {
            return false
        }
        
        let lineContent = line.lineContent
        let startGlobalPoint = new Point(lineContent.startPointX + line.x, lineContent.startPointY + line.y);
        let endGlobalPoint = new Point(lineContent.endPointX + line.x, lineContent.endPointY + line.y);
        let startHit = targetNode.value.hitPoint(startGlobalPoint, lineContent.getLineStartMargin());
        if (startHit.x > -1 && startHit.y > -1) {
            return false
        }

        let endHit = targetNode.value.hitPoint(endGlobalPoint, lineContent.getLineEndMargin());
        if (endHit.x > -1 && endHit.y > -1) {
            return false
        }
        return true
    }

    isStraightLine(line) {
        if (line == null || line.isEmpty() || line.lineContent == null || line.type != MindElementType.NODE_CONNECT_LINE ||
            line.lineContent.connectLineType == ConnectLineType.CURVE_LINE) {
            return false
        }
        let lineContent = line.lineContent
        if (lineContent.connectLineType == ConnectLineType.STRAIGHT_ARROW_LINE || 
            lineContent.connectLineType == ConnectLineType.STRAIGHT_CIRCULAR_LINE) {
            return true
        }
        if (lineContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
            if (lineContent.rightAnglePoints.length == 0) {
                return true
            }
            let startPoint = lineContent.startPoint();
            let endPoint = lineContent.endPoint();
            if (startPoint.x == endPoint.x && startPoint.y == endPoint.y) {
                return false;
            }
            let lineLength = Util.getPointSpacing(startPoint, endPoint)
            for (let index = 0; index < lineContent.rightAnglePoints.length; index++) {
                let point = lineContent.rightAnglePoints[index]
                let space = Util.getPointSpacing(startPoint, point) + Util.getPointSpacing(endPoint, point)
                if (Math.abs(space - lineLength) > 3) {
                    return false
                }
            }
            return true
        }
        return false;
    }
}

export default NodeConnectLineCalcul