import Strings from "../../../utils/Strings";
import MindElementContent from "./MindElementContent";

class CheckBoxContent extends MindElementContent {
    constructor(selected = false) {
        super(20, 20);
        this.selected = selected;
    }
    
    copy() {
        let data = new CheckBoxContent();
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;

        data.selected = this.selected;
        return data;
    }

   equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.selected != this.selected;
        return !result;
    }

    stickStyle( data) {
        if (data == null) {
            return;
        }
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
    }
}

export default CheckBoxContent