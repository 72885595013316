<template>
  <!-- 备注 -->
  <div id="remark-box" class="complie-addmodel-remark">
    <div class="complie-titel-top">
      <section class="remark-titel-left" @click="turnOnScanExtraction">
        <img
          src="../../../../assets/images/ocr/scan_extraction.svg"
          alt=""
        />
        <span class="scan-extraction-title">
          {{ getString(strings.Mind_Scan_Extraction) }}
        </span>
      </section>
      <div class="complie-titel-center">
        <!-- <div>
          <img
            src="../../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_remark_af.png"
            alt=""
          />
        </div> -->
        <div class="complie-titel-text">
          {{ getString(strings.Mind_Edit_Remarks) }}
        </div>
      </div>

      <div class="complie-right-hide" @click="onHideRemarkModel">
        <a-icon type="close" :style="{ fontSize: '15px' }" />
      </div>
    </div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item :style="{ marginBottom: editState ? '28px' : '14px', marginTop:'5px' }">
        <a-input
          ref="remarkContent"
          v-model="form.desc"
          style="resize: none"
          type="textarea"
          class="complie-content"
          :placeholder="getString(strings.Mind_Edit_Remarks_Tips)"
          :disabled="editState"
          :class="{ input_Accout: inputAccout }"
          @focus="focusInput()"
          @blur="blurInput()"
        />
      </a-form-model-item>

      <div v-if="!editState" class="complie-btn">
        <a-form-model-item>
          <a-button @click="onHideRemarkModel">
            {{ getString(strings.Global_Cancel) }}
          </a-button>
          <a-button type="primary" @click="onSubmit">
            {{ getString(strings.Global_Ok) }}
          </a-button>
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import complieAddModelRemark from "../../../../assets/css/complieAddModelRemark/complieAddModelRemark.less";
import getString from "../../../../common/lang/language";
import strings from "../../../../common/lang/strings";
import VueDataEvent from "../../../../core/core/basemode/VueDataEvent";
import { getJwt } from "../../../../common/netWork/base";
import { ocrScanExtractTypes } from "../../../../assets/js/GlobalVariable.js";

export default {
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 20 },
      form: {
        desc: "",
      },
      inputAccout: false,
      editState: false,
      strings: strings,
    };
  },
  props: {
    mindMapingType: {
      type: VueDataEvent,
      defualt() {
        return new VueDataEvent(false);
      },
    },
  },
  mounted() {
    this.isEditState();
  },
  methods: {
    ...mapMutations([
      "setShowAddRemark",
      "setAddRemarkContent",
      "setUseShortcut",
      "showLoginModel",
      "setOcrScanModel",
    ]),
    getString(i) {
      return getString(i);
    },
    isEditState() {
      let path = this.$route.path;
      if (
        path == "/mindmap" ||
        (path == "/gmap" && this.mindMapingType.value == true) ||
        (path == "/arvhiveMap" && this.mindMapingType.value == true)
      ) {
        this.editState = false;
      } else {
        this.editState = true;
      }
    },
    //隐藏备注模态框
    onHideRemarkModel() {
      this.setShowAddRemark(false);
      this.inputAccout = false;
    },
    //提交备注信息
    onSubmit() {
      this.setShowAddRemark(false);
      this.setAddRemarkContent(this.form.desc);
      this.inputAccout = false;
      // this.form.desc = "";
    },
    focusInput(){
       this.setUseShortcut(false);
    },
    blurInput(){
      this.setUseShortcut(true);
    },
    turnOnScanExtraction(){
      // 打开备注扫描提取
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
      } else {
        this.setOcrScanModel({value:true,type:ocrScanExtractTypes.ScanExtractionRemarks});
        this.setAddRemarkContent(this.form.desc);
      }
    }
  },

  watch: {
    "$store.state.remarkContent"(newRemarkContent, olRemarkContent) {
      this.form.desc = newRemarkContent;
    },
    "$store.state.showAddRemark"(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.remarkContent.focus();
        });
      }
    },
    mindMapingType: {
      handler(newVal) {
        this.isEditState();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.complie-addmodel-remark {
  width: 524px;
  // min-height: 424px;
  border-radius: 8px;
  z-index: 1000;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 40px 20px rgba(58, 58, 58, 0.4);
  background-color: #f1f2f3;
  .input_Accout {
    border: 1px solid red;
  }
  .complie-titel-top {
    width: 524px;
    height: 36px;
    position: relative;
    background: #f1f2f3;
    border-radius: 8px;
    .remark-titel-left{
      height: inherit;
      position: absolute;
      left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .scan-extraction-title{
        margin-left: 4px;
        font-size: 14px;
        color: #555;
      }
    }
    .complie-titel-center {
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      .complie-titel-text {
        font-size: 16px;
        color: #111;
        font-weight: 700;
        // // width: 36px;
        // white-space: nowrap;
        // height: 25px;
        // font-size: 18px;
        // /*font-weight: bold;*/
        // opacity: 1;
        // margin: 10px;
        // display: flex;
        // align-items: center;
      }
    }
    .complie-right-hide {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      // top: 20px;
      top: 50%;
      transform: translate(0, -50%);
      right: 15px;
      cursor: pointer;
      transition: 0.3s;
    }
    .complie-right-hide:hover {
      transition: 0.3s;
      color: #fd492b;
      transform: translate(0, -50%) rotate(180deg);
    }
  }

  .complie-content {
    width: 504px;
    height: 278px;
    line-height: 1.75;
    font-size: 13px;
    color: #333333;
    border: 0px solid #ffffff01;
    border-radius: 8px;
    background: #f1f2f3;
  }
  .complie-btn {
    width: 524px;
    button {
      height: 32px;
      border-radius: 8px;
    }
  }
}
</style>