// package mind.yushu.com.mindmap.main.edit;

// import android.graphics.Bitmap;

// import mind.yushu.com.mindmap.core.common.PermissionManager;
// import mind.yushu.com.mindmap.core.storage.CacheFileManager;
// import mind.yushu.com.mindmap.main.edit.tools.MenuView;

// import mind.yushu.com.mindmap.main.uidata.ToolItemData;

// import mind.yushu.com.mindmap.utils.UiUtil;
// import mind.yushu.com.mindmap.viewmodel.datatype.ConnectLineType;
// import mind.yushu.com.mindmap.viewmodel.datatype.LineLayout;
// import mind.yushu.com.mindmap.viewmodel.datatype.NodeLayoutType;
// import mind.yushu.com.mindmap.viewmodel.datatype.TimeLineNodeType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.IconElementContent;
// import mind.yushu.com.mindmap.viewmodel.vm.EditMindmapVM;

import TimeLineNodeType from "../../../viewmodel/datatype/TimeLineNodeType"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"
import LineLayout from "../../../viewmodel/datatype/LineLayout"
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType"
import MindElementType from "../../../viewmodel/datatype/MindElementType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */

class MindOperateUIControllerView {

    constructor(model, editMindmapActivity) { //editMindmapActivity
        this.viewModel = model;
        this.editMindmapActivity = editMindmapActivity;
    }

    MindOperateUIControllerView(viewModel, editMindmapActivity) {
        this.viewModel = viewModel;
        this.editMindmapActivity = editMindmapActivity;
    }

    onSelected(type) {
        switch (type) {
            case MindElementType.ADD:
                //在添加节点的时候保存输入框内容。
                this.viewModel.delegate.editmapScrollView.hideInput();
                this.viewModel.addChildMindElement();
                break;
            case MindElementType.ADD_BROTHER_BOTTOM:
                //在添加节点的时候保存输入框内容。
                this.viewModel.delegate.editmapScrollView.hideInput();
                this.viewModel.addYoungerBrotherMindElement();
                break;
            case MindElementType.ADD_BROTHER_TOP:
                //在添加节点的时候保存输入框内容。
                this.viewModel.delegate.editmapScrollView.hideInput();
                this.viewModel.addBrotherMindElement();
                break;
            case MindElementType.QUIT:
                this.viewModel.onClose();
                break;
            case MindElementType.CHECK_BOX:
                this.viewModel.changeCheckBox(false, -1);
                break;
            case MindElementType.IMAGE:
                this.editMindmapActivity.selectImage();
                break;
            case MindElementType.CONTENT_GENERALIZATION:
                this.viewModel.addGeneralizationContent();
                break;
            case MindElementType.STRAIGHT_LINE:
                this.viewModel.changeLineLayout(LineLayout.STRAIGHT_LINE);
                break;
            case MindElementType.STRAIGHT_LINE_2:
                this.viewModel.changeLineLayout(LineLayout.STRAIGHT_LINE_2);
                break;
            case MindElementType.CURVE_LINE:
                this.viewModel.changeLineLayout(LineLayout.CURVE_LINE);
                break;
            case MindElementType.CURVE_LINE_2:
                this.viewModel.changeLineLayout(LineLayout.CURVE_LINE_2);
                break;
            case MindElementType.CURVE_LINE_CIRCULAR:
                this.viewModel.changeLineLayout(LineLayout.CURVE_LINE_CIRCULAR);
                break;
            case MindElementType.STRAIGHT_CURVE_LINE:
                this.viewModel.changeLineLayout(LineLayout.STRAIGHT_CURVE_LINE);
                break;
            case MindElementType.CURVE_LINE_AVERAGE:
                this.viewModel.changeLineLayout(LineLayout.CURVE_LINE_AVERAGE);
                break;
            case MindElementType.RIGHT_ANGLE_LINE:
                this.viewModel.changeLineLayout(LineLayout.RIGHT_ANGLE_LINE);
                break;
            case MindElementType.RIGHT_ANGLE_CORNER_LINE:
                this.viewModel.changeLineLayout(LineLayout.RIGHT_ANGLE_CORNER_LINE);
                break;
            case MindElementType.RIGHT_ANGLE_CORNER_ARROW_LINE:
                this.viewModel.changeLineLayout(LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE);
                break;
            case MindElementType.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE:
                this.viewModel.changeLineLayout(LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE);
                break;
            case MindElementType.FULL_RIGHT_ANGLE_CORNER_LINE:
                this.viewModel.changeLineLayout(LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE);
                break;
            case MindElementType.LAYOUT_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_RIGHT);
                break;
            case MindElementType.LAYOUT_LEFT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_LEFT);
                break;
            case MindElementType.LAYOUT_LEFT_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_LEFT_RIGHT);
                break;

            case MindElementType.LAYOUT_RIGHT_LEFT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_RIGHT_LEFT);
                break;
            case MindElementType.LAYOUT_RIGHT_LEFT_CLOCKWISE:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE);
                break;    

            case MindElementType.LAYOUT_BRACKETS_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_BRACKETS_RIGHT);
                break;
            case MindElementType.LAYOUT_BRACKETS_LEFT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_BRACKETS_LEFT);
                break;
            case MindElementType.LAYOUT_CIRCLE:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_CIRCLE);
                break;
            case MindElementType.LAYOUT_BRIDGE:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_BRIDGE);
                break;
            case MindElementType.LAYOUT_RADIATE:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_RADIATE);
                break;
            case MindElementType.LAYOUT_BOTTOM:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_BOTTOM);
                break;
            case MindElementType.LAYOUT_TOP:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TOP);
                break;
            case MindElementType.LAYOUT_TREE_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TREE_RIGHT);
                break;
            case MindElementType.LAYOUT_TOP_TREE_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TOP_TREE_RIGHT);
                break;
            case MindElementType.LAYOUT_TOP_TREE_LEFT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TOP_TREE_LEFT);
                break;
            case MindElementType.LAYOUT_TREE_LEFT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TREE_LEFT);
                break;
            case MindElementType.LAYOUT_TREE_LEFT_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT);
                break;
            case MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT);
                break;
            case MindElementType.LAYOUT_HORIZONTAL_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT);
                break;
            case MindElementType.LAYOUT_VERTICAL_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_VERTICAL_RIGHT);
                break;
            case MindElementType.LAYOUT_FISH_RIGHT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_FISH_RIGHT);
                break;
            case MindElementType.LAYOUT_FISH_LEFT:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_FISH_LEFT);
                break;
            case MindElementType.LAYOUT_FORM:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_FORM);
                break;
            case MindElementType.LAYOUT_FORM_HORIZONTAL:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_FORM_HORIZONTAL);
                break;
            case MindElementType.LAYOUT_FREE_TREE:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_FREE_TREE);
                break;
            case MindElementType.LAYOUT_BUBBLE:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_BUBBLE);
                break;
            case MindElementType.LAYOUT_VERTICAL:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_VERTICAL);
                break;
            case MindElementType.LAYOUT_HORIZONTAL:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_HORIZONTAL);
                break;
            case MindElementType.LAYOUT_HORIZONTAL_S:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_HORIZONTAL_S);
                break;
            case MindElementType.LAYOUT_LADDER:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_LADDER);
                break;
            case MindElementType.LAYOUT_HORIZONTAL_CRISSCROSS:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_HORIZONTAL_CRISSCROSS);
                break;
            case MindElementType.LAYOUT_TIME_ARC:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TIME_ARC);
                break;
            case MindElementType.LAYOUT_TRIANGLE:
                this.viewModel.changeNodeLayout(NodeLayoutType.LAYOUT_TRIANGLE);
                break;
            case MindElementType.SAVE_PNG:
                this.editMindmapActivity.openExporPictureView();
                break;
            case MindElementType.SAVE_WATERMARK_PNG:
                this.editMindmapActivity.openExporPictureView();
                break;
            case MindElementType.SAVE_PDF:
                this.editMindmapActivity.exportPdf();
                break;
            case MindElementType.FREE_NODE:
                this.viewModel.setFreeNodeStatus();
                break;
            case MindElementType.NODE_CONNECT_LINE_ARROW:
                this.viewModel.selectedNodeConnectLineStatus(ConnectLineType.STRAIGHT_ARROW_LINE);
                break;
            case MindElementType.NODE_CONNECT_LINE_CIRCULAR:
                this.viewModel.selectedNodeConnectLineStatus(ConnectLineType.STRAIGHT_CIRCULAR_LINE);
                break;
            case MindElementType.NODE_CONNECT_LINE_RIGHT_ANGLE:
                this.viewModel.selectedNodeConnectLineStatus(ConnectLineType.RIGHT_ANGLE_LINE);
                break;
            case MindElementType.NODE_CONNECT_LINE_CURVE:
                this.viewModel.selectedNodeConnectLineStatus(ConnectLineType.CURVE_LINE);
                break;
            case MindElementType.MAP_SPLIT:
                this.viewModel.onSplitAndConnectMap();
                break;
            case MindElementType.BAOWEI_VIEW:
                this.viewModel.encircleNodes();
                break;
            case MindElementType.MORE:
                this.editMindmapActivity.closeStyleModelPanel();
                this.editMindmapActivity.openMorePanel();
                break;
            case MindElementType.TIME_LINE_CIRCULAR:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_CIRCULAR);
                break;
            case MindElementType.TIME_LINE_DIAMOND:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_DIAMOND);
                break;
            case MindElementType.TIME_LINE_CIRCULAR_CONNECT_HEAD:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD);
                break;
            case MindElementType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE);
                break;
            case MindElementType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER);
                break;
            case MindElementType.TIME_LINE_CIRCULAR_ORDER:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER);
                break;
            case MindElementType.TIME_LINE_CIRCULAR_ARROW_HEAD:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD);
                break;
            case MindElementType.TIME_LINE_CIRCULAR_ORDER_1:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER_1);
                break;
            case MindElementType.TIME_LINE_HEXAGON_ORDER:
                this.viewModel.setTimeLineType(TimeLineNodeType.TIME_LINE_HEXAGON_ORDER);
                break;

            case MindElementType.OPEN_UPGRADE:
                //                UIUtil.openUpgrade(viewController: self)
                break;
            case MindElementType.SHARED_IMAGE:
                this.editMindmapActivity.shareImage();
                break;
            case MindElementType.SHARED_ORIGINAL_DOCUMENT:
                this.viewModel.sharedFile();
                break;
            case MindElementType.MIND_HELP:
                //                openHelp()
                break;
            case MindElementType.BACKWARD_RETREAT:
                this.viewModel.backwardRetreat();
                break;
            case MindElementType.FORWARD:
                this.viewModel.forward();
                break;
            case MindElementType.ADD_LINK:
                this.viewModel.openLinkPanel();
                break;
            case MindElementType.ADD_REMARKS:
                this.viewModel.openRemarksPanel();
                break;
            case MindElementType.MAP_CONNECT_LINE:
                this.viewModel.openSelecteConnectMapPanel();
                break;
            case MindElementType.SAVE_ICLOUD:
                //                if StorageManager.shared().isUpgrade():
                //                    onSaveIcloud()
                //                } else:
                //                    UIUtil.showUpgradeAlertMessage(in: self)
                //                }
                break;
            case MindElementType.OUTLINE:
                this.viewModel.onChangeMindDisplayType();
                break;
            case MindElementType.NODE_DELETE:
                //                removeText()
                break;
            case MindElementType.NODE_MOVE_BACKWARD:
                this.viewModel.onMoveBackward()
                break;
            case MindElementType.NODE_MOVE_FORWARD:
                this.viewModel.onMoveForward()
                break;
            case MindElementType.SHARED_MARKDOWN_DOCUMENT:
                //                this.viewModel.sharedDarkdownFile()
                break;
            case MindElementType.OPEN_STYLE:
                this.editMindmapActivity.openStyleModelPanel();
                break;
            case MindElementType.SHARE_TEMPLATE:
                this.editMindmapActivity.shareTemplate();
                break;
            case MindElementType.SHARE_URL:
                // new ShareUrlDialog().loadData(editMindmapActivity, viewModel);
                break;
            case MindElementType.SHARE_CRISPS:
                this.editMindmapActivity.shareCrisps();
                break;
            case MindElementType.AUDIO_FILE:
                // if (MindMe.get().isLogin()) {
                //     if (this.viewModel.isGlobalAudio()){
                //         this.editMindmapActivity.showDelAudioDialog();
                //     }else {
                //         this.editMindmapActivity.audioFile();
                //     }
                // } else {
                //     // IntentFactory.INSTANCE.gotoLoginActivity(editMindmapActivity);
                // }
                break;
            case MindElementType.MATERIAL_LIBRARY:
                this.editMindmapActivity.startMaterialListActivity();
                break;
            case MindElementType.MIND_STYLE_ADD_DOODLE:
                this.editMindmapActivity.startMindWhiteBoardByActivity();
                break;
            case MindElementType.PLAY_MINDMAP:
                this.editMindmapActivity.openPlaySetting();
                break;
            case MindElementType.PAUSE_MINDMAP:
                this.editMindmapActivity.pausePlayMap();
                break;
            case MindElementType.STOP_MINDMAP:
                this.editMindmapActivity.stopPlayMap();
                break;
            case MindElementType.DOWN_KEYBOARD:
                this.editMindmapActivity.hideSoftKeyboard();
                break;
            case MindElementType.NODE_BOLD_STYLE:
                // this.setNodeTextStyle(data, Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_BOLD, Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_BOLD_VALUE);
                break;
            case MindElementType.NODE_ITALICS_STYLE:
                // setNodeTextStyle(data, Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_ITALICS, Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_ITALICS_VALUE);
                break;
            case MindElementType.NODE_STRIKETHROUGH_STYLE:
                // setNodeTextStyle(data, Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_STRIKETHROUGH, Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_STRIKETHROUGH_VALUE);
                break;
            case MindElementType.NODE_OUTLINE_COLOR_STYLE:
                // openColorsView();
                break;
            case MindElementType.CLEAR_TEXT_STYLE:
                this.viewModel.clearTextStyle(viewModel.selectedNodeId);
                break;
            case MindElementType.TEXT_UP_KEYBOARD:
                this.viewModel.setTextFontSizeUp(viewModel.selectedNodeId);
                break;
            case MindElementType.TEXT_DOWN_KEYBOARD:
                this.viewModel.setTextFontSizeDown(viewModel.selectedNodeId);
                break;
            case MindElementType.MIND_SHEET_PAGE:
                // showMindSheetPageDialog("");
                break;
            case MindElementType.MIND_SEE:
                this.editMindmapActivity.hideOperationView();
                let mode = this.editMindmapActivity.getBottomSheetPageMode();
                this.editMindmapActivity.showSheetPageMode(this.editMindmapActivity.editMindmapVM.getMindServerId(),"", mode,this.editMindmapActivity.editMindmapVM, false);

                break;
            case MindElementType.EXIT_PLAY:
                this.editMindmapActivity.onQuitPlayComplete();
                break;
            case MindElementType.SHARE_GROUP:
                // if(TextUtils.isEmpty(viewModel.getMindServerId())){
                //     ToastUtil.show(R.string.not_synchronized_to_server);
                // }else{
                //     Intent intent=new Intent(editMindmapActivity, ShareGroupMindFromActivity.class);
                //     String mindID = editMindmapActivity.editMindmapVM.getMindServerId();
                //     intent.putExtra(DataIntent.getPUT_ID(),mindID);
                //     this.editMindmapActivity.startActivity(intent);
                // }
                break;
            case MindElementType.REPORT:
                this.editMindmapActivity.report();
                break;
            case MindElementType.EXPLAIN:
                this.viewModel.addExplainContent();
                break;
            default:
        }
        //System.out.println("onSelected -> " + data.type.name());
    }
}

export default MindOperateUIControllerView
